export function chatResultFormater(parserText) {
    let html = markdownToHTML(parserText)
    const textList = html.split('\n')
    const htmlList = []
    for (const text of textList) {
        const htmlSlice = checkFormatLink(text)
        if (typeof (htmlSlice) === 'string') {
            htmlList.push(htmlSlice)
        }
    }
    html = htmlList.join('<br/>')

    return html
}



export function generateHTMLTable(headers, data) {
    // 创建表格元素
    var table = document.createElement('table');
    table.setAttribute('border', '0'); // 移除边框
    table.setAttribute('cellspacing', '0');
    table.setAttribute('cellpadding', '5');

    // 添加表头
    var thead = document.createElement('thead');
    thead.className = "table-header"
    var headerRow = document.createElement('tr');
    headers.forEach(function (header) {
        var headerCell = document.createElement('th');
        headerCell.innerHTML = header;
        headerRow.appendChild(headerCell);
    });
    thead.appendChild(headerRow);
    table.appendChild(thead);

    // 添加表格数据
    var tbody = document.createElement('tbody');
    tbody.className = "table-body"
    data.forEach(function (rowData) {
        var row = document.createElement('tr');
        rowData.forEach(function (cellData) {
            var cell = document.createElement('td');
            cell.innerHTML = cellData;
            row.appendChild(cell);
        });
        tbody.appendChild(row);
    });
    table.appendChild(tbody);
    // 返回表格的HTML
    return table.outerHTML;
}




export function markdownToHTML(markdown) {
    // 替换标题
    markdown = markdown.replace(/^# (.+)$/gm, '<h1>$1</h1>');
    markdown = markdown.replace(/^## (.+)$/gm, '<h2>$1</h2>');
    markdown = markdown.replace(/^### (.+)$/gm, '<h3>$1</h3>');
    markdown = markdown.replace(/^#### (.+?)$/gm, '<h4>$1</h4>');
    markdown = markdown.replace(/^##### (.+?)$/gm, '<h5>$1</h5>');
    markdown = markdown.replace(/^###### (.+?)$/gm, '<h6>$1</h6>');

    // // 替换段落
    // markdown = markdown.replace(/\n\n/g, '</p><p>');
    // 替换粗体
    markdown = markdown.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');

    // 替换斜体
    markdown = markdown.replace(/\*(.+?)\*/g, '<em>$1</em>');

    // 替换无序列表
    markdown = markdown.replace(/\n\s*-\s(.+?)(?=\n|$)/g, '<ul style="display:block;margin:0;padding:0 -8px 0px 8px;"><li>$1</li></ul>');

    // 替换有序列表
    // markdown = markdown.replace(/\n\s*\d+\.\s(.+?)(?=\n|$)/g, '<ol><li>$1</li></ol>');

    // 替换链接
    // markdown = markdown.replace(/\[([^\]]+)]\(([^)]+)\)/g, '<a href="$2">$1</a>');

    // 替换图片
    // markdown = markdown.replace(/!\[([^\]]+)]\(([^)]+)\)/g, '<img src="$2" alt="$1">');

    return markdown;
}

export const encodeHtml = (s) => {
    const textList = s.split('\n')
    // eslint-disable-next-line no-control-regex
    const REGX_HTML_ENCODE = /"|&|'|<|>|[\x00-\x20]|[\x7F-\xFF]|[\u0100-\u2700]/g;
    const newTexts = []
    for (const text of textList) {
        const newText = text.replace(REGX_HTML_ENCODE,
            function ($0) {
                var c = $0.charCodeAt(0), r = ["&#"];
                if (c !== 32) {
                    c = (c == 0x20) ? 0xA0 : c;
                    r.push(c); r.push(";");
                    return r.join("");
                } else {
                    return $0
                }
            });
        newTexts.push(newText)
    }
    return newTexts.join("\n")
};


const checkFormatLink = (text) => {
    const newText = text.replace(
        // eslint-disable-next-line no-useless-escape
        /(https?:\/\/)?(([0-9a-z.]+\.[a-z]+)|(([0-9]{1,3}\.){3}[0-9]{1,3}))(:[0-9]+)?(\/[0-9a-z%/.\-_]*)?(\?[0-9a-z=&%_\-]*)?(\#[0-9a-z=&%_\-]*)?/ig,
        function ($0, $1) {
            if ($0.includes("http://") || $0.includes("https://")) {
                return '<a href="'
                    + ($1 ? '' : 'http://')
                    + $0 + '" target="_blank" >' + $0 + '</a>';
            }
            return $0

        }
    )

    return newText
}

export const checkIsLink = (text) => {
    let isLink = false
    if (text) {
        text.replace(
            // eslint-disable-next-line no-useless-escape
            /(https?:\/\/)?(([0-9a-z.]+\.[a-z]+)|(([0-9]{1,3}\.){3}[0-9]{1,3}))(:[0-9]+)?(\/[0-9a-z%/.\-_]*)?(\?[0-9a-z=&%_\-]*)?(\#[0-9a-z=&%_\-]*)?/ig,
            function ($0) {
                if ($0.includes("http://") || $0.includes("https://")) {
                    isLink = true
                    return $0
                }
                return $0

            }
        )
    }

    return isLink
}

// const formatresult = (text, start_flag, end_flag)=>{

// }