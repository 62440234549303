<template>
  <div class="chatView_container">
    <!-- 使用 v-if 控制子组件的显示和隐藏 -->
    <SubChatView v-if="showContent" @triggerShow="toggleShowContent" :bot-id="botId" :moresProblem="moresProblem"/>
    <div class="chatView_container_header" v-else-if="!isMobile">
      <img src="@/assets/img/sentosa/sentosa_logo.svg" class="assistant-logo"/>
    </div>
  </div>
</template>

<script setup>
import SubChatView from './components/SubChatView.vue';
import { onMounted, ref} from 'vue';
import {getChatBot, getChatBotId, requestLoginEncryption} from "@/api/api";
import {useTokenStore} from "@/store/token";
import CryptoJS from 'crypto-js';

//
// 通过 ref 定义状态变量
const showContent = ref(false);
const moresProblem = ref([]);
const tokenStore = useTokenStore();
const botId = ref('')
const isMobile = ref(true)


// 切换显示状态的函数
const toggleShowContent = () => {
  showContent.value = false;
  setTimeout(() => {
    showContent.value = true;
  }, 20); // 确保有时间间隔来触发生命周期
};

// 异步请求登录并获取常见问题
const fetchData = async () => {
  try {
    // localStorage.setItem('hasChat', 'true')
    // 登录请求
    showContent.value = false

    const timestamp = Date.now(); // 例如：当前时间戳
    const secretKey =  "Flzx3000c!"
    // 拼接时间戳和密钥
    const stringToSign = `${timestamp}\n${secretKey}`
    // 生成HMAC-SHA256签名
    const hmac = CryptoJS.HmacSHA256(stringToSign, secretKey)
    // 转换为Base64字符串
    const base64Sign = CryptoJS.enc.Base64.stringify(hmac)



    const loginRes = await requestLoginEncryption({
      "username": "wiz_testing",
      "ciphertext": base64Sign,
      "timestamp": timestamp
    })
    const token = loginRes?.data?.access_token || null;

    console.log('loginRes===>',loginRes)
    if (token) {
      tokenStore.setToken(token, null);
    }
    const botIdTemp = await getChatBotId();
    localStorage.setItem('botId', botIdTemp)
    botId.value = botIdTemp
    // 获取机器人常见问题
    const botInfo = await getChatBot(botIdTemp);
    moresProblem.value = botInfo.common_problem.map(item => item.value);
    showContent.value = true;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const checkScreenSize = () => {
  const mediaQuery = window.matchMedia('(max-width: 768px)');
  isMobile.value = mediaQuery.matches;

  // 监听变化
  mediaQuery.addEventListener('change', (e) => {
    isMobile.value = e.matches;
  });
};

// 组件挂载时调用
onMounted(()=>{
  checkScreenSize(); // 初次加载时检查
  window.addEventListener('resize', checkScreenSize); // 监听后续变化

  fetchData()
});
</script>

<style lang="scss">
.chatView_container {
  width: 100%;
  height: 100%;
  font-family: Montserrat;


  .chatView_container_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;

    .assistant-logo {
      width: 211px;
      height: 80px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
