<template>
    <el-drawer :with-header="false" v-bind="$attrs" size="60%" style="padding: 4px;  font-family: Montserrat">
        <div class="file-body">
            <div class="preview-wrapper" v-if="documentType == 'pdf'">
                <div class="file-header">
                    <i class="bi bi-file-earmark-pdf-fill" style="margin-left: 24px;"></i> <div
                        class="header-display-name"> {{ taskInfo.source_data }}</div>
                    <i class="bi bi-download" style="cursor: pointer; margin-left: 24px; " @click="download(sourceUrl)"></i>
                </div>
                <PdfViewer :url="sourceUrl"></PdfViewer>
            </div>
            <div v-else class="preview-wrapper">
                <div class="file-header" style="padding: 12px;" >
                    <template v-if="taskInfo.task_type == 'CRAWLING'" >
                        <i class="bi bi-link-45deg" style="margin-left: 24px;"></i> <div class="header-display-name">
                                <a :href="taskInfo.source_data" target="_blank"> {{ transformSourceName(taskInfo.source_data) }}</a></div>
                    </template>
                    <template v-else>
                        <i class="bi bi-file-earmark-text-fill" style="margin-left: 24px;"></i> <div
                            class="header-display-name"> {{ transformSourceName(taskInfo.source_data) }}</div>
                        <i class="bi bi-download"   style="cursor: pointer; margin-left: 24px; "
                            @click="download(sourceUrl)"></i>
                    </template>
                </div>
                <TextViewer :text="text"></TextViewer>
            </div>
        </div>
    </el-drawer>
</template>

<script setup >
import PdfViewer from "@/components/fileViewers/PdfViewer.vue"
import TextViewer from "@/components/fileViewers/TextViewer.vue"
import { computed, watch, ref } from "vue"
import { getTaskExtratData } from "@/api/api"


const props = defineProps({
    taskInfo: {
        type: Object,
        default: () => {
            return {}
        }
    }
})

// 将__COLON__、__SLASH__和__DOT__替换为对应符号
const transformSourceName = (sourceName) => {
  let result = sourceName
      .replace(/__COLON__/g, ':')
      .replace(/__SLASH__/g, '/')
      .replace(/__DOT__/g, '.');
  const txtRegex = /\.txt$/;
  if (txtRegex.test(result)) {
    result = result.replace(txtRegex, '');
  }
  return result;
};

const text = ref("")
watch(() => props.taskInfo, async () => {
    if (props.taskInfo.mime_type !== "application/pdf") {
        const data = await getTaskExtratData(props.taskInfo.id)
        const documents = data.documents
        let t = ''
        for (const document of documents) {
            t += document.page_content || ''
        }
        text.value = t

    }
})

const download = (url) => {
    if (!url) {
        return
    }
    const a = document.createElement('a');
    a.href = url;
    a.download = 'file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const documentType = computed(() => {
    if (props.taskInfo.mime_type === "application/pdf") {
        return "pdf"
    } else {
        return "text"
    }
})

const sourceUrl = computed(() => {
    if (props.taskInfo.id) {
        return `/enterprise_search/v1/chatbot/task/${props.taskInfo.id}/source`
    }
    else {
        return ""
    }
})

</script>
<style>
.file-body {
    width: 100%;
    height: 100%;
  font-family: Montserrat;
}

.preview-wrapper {
    width: 100%;
    height: 100%;
  font-family: Montserrat;

    .file-header {
      font-family: Montserrat;
        width: 100%;
        height: 24px;
        display: flex;
        .header-display-name{
          font-family: Montserrat;
            max-width: 50%;
            margin-left: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
        }
    }
}</style>