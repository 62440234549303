<template>
  <div class="search-form-history">
    <el-form-item>
      <span>Session ID</span>
      <el-input v-model="username" size="default" placeholder="Please Enter" clearable></el-input>
    </el-form-item>
    <el-form-item>
      <span>Name</span>
      <el-input v-model="serialNum" size="default" placeholder="Please Enter" clearable></el-input>
    </el-form-item>
    <el-form-item>
      <span>Range</span>
      <el-select v-model="dimensionality" placeholder="Select Dimensionality" >
        <el-option
            v-for="item in dimensionalityOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item>
      <span>{{ dimensionality === 'session' ? 'Feedback score' : 'Feedback' }}</span>
      <el-select
          v-model="feedback"
          placeholder="Select Feedback"
          :multiple="dimensionality === 'session'"
          collapse-tags
          collapse-tags-tooltip>
        <el-option
            v-for="item in feedbackOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item>
      <span>Date</span>
      <el-date-picker
        v-model="duration"
        size="default"
        type="daterange"
        start-placeholder="Start Date"
        end-placeholder="End Date"
        value-format="YYYY-MM-DD"
        clearable>
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" style="margin-bottom: 5px;" @click="handleExport">Export</el-button>
    </el-form-item>
  </div>
</template>
<script setup>
import { ref, watchEffect, computed, watch } from 'vue';


const props = defineProps({
  username: { type: String, default: "" },
  name: { type: String, default: "" },
  duration: { type: Array, default: () => [] },
  recommendation: { type: String, default: "" },
  state: { type: String, default: "" },
  dimensionality: { type: String, default: "" },
  feedback: { type: String, default: "" }
})
const duration = ref(props.duration)
const recommendation = ref(props.recommendation)
const state = ref(props.state)
const username = ref(props.username)
const serialNum = ref(props.serialNum)
const dimensionality = ref(props.dimensionality)
const feedback = ref(props.feedback)

// Mock data for dropdowns
const dimensionalityOptions = [
  { value: 'session', label: 'Session' },
  { value: 'message', label: 'Dialogue' },
]

// 根据 dimensionality 使用不同的 feedbackOptions
const feedbackOptions = computed(() => {
  if (dimensionality.value === 'session') {
    return [
      { value: 'all', label: 'All' },
      { value: '0', label: 'Empty' },
      { value: '1', label: '1' },
      {value:'2',label:'2'},
      {value:'3',label:'3'},
      {value:'4',label:'4'},
      {value:'5',label:'5'}
    ]
  } else {
    return [
      { value: '', label: 'All' },
      { value: 'like', label: 'Helpful' },
      { value: 'dislike', label: 'Not Helpful' },
      {value:'no_behavior',label:'Empty'}
    ]
  }
})

// 当 dimensionality 改变时重置 feedback
watch(dimensionality, () => {
  feedback.value = ''
})

const emits = defineEmits([
  "search",
  "update:duration",
  "update:recommendation",
  "update:state",
  "update:username",
  "update:serialNum",
  "update:dimensionality",
  "update:feedback",
  "export"
])
watchEffect(() => {
  emits("update:duration", duration.value)
  emits("update:recommendation", recommendation.value)
  emits("update:state", state.value)
  emits("update:username", username.value)
  emits("update:serialNum", serialNum.value)
  emits("update:dimensionality", dimensionality.value)
  emits("update:feedback", feedback.value)
})

const handleExport = () => {
  emits("export")
}
</script>
<style scoped lang="scss">


:deep(.el-range-input) {
  font-weight: 500!important;
}
:deep(.el-input__inner) {
  font-weight: 500!important;
}



.search-form-history {
  display: flex;
  background-color: #ffffff;
  overflow: auto;



  .el-form-item {
    margin-right:12px ;
    
    // Add this style for the export button container
    &:last-child {
      margin-left: auto;
      margin-right: 0;
    }
    
    .el-input {
      :deep(.el-input__inner) {
        margin-right: 12px;

      }


      :deep(.el-input__suffix) {
        position: fixed;
        right: 8px;
      }
    }
    span {
      width: 100%;
      font-size: 14px;
      line-height: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .el-input {
    height: 40px;
    :deep(.el-input__wrapper) {
      //border: 1px solid #dfeaf2;

      padding: 2px 12px;
    }


  }

  :deep(.el-date-editor) {
    height: 36px;
    padding: 1px 12px;
  }

  .el-select {
    width: 100%;
    min-width: 100px;
    :deep(.el-select__wrapper) {
      height: 40px;
    }
  }

  .el-button {
    margin-top: 40px;
    font-size: 1rem;
    padding: 17px 15px;
  }
}
</style>
