<template>
  <div class="question_container">
    <div class="question_content">
      <SearchForm v-model:duration="duration" v-model:recommendation="recommendation" v-model:state="state"
                  v-model:username="username" v-model:serialNum="serialNum" v-model:dimensionality="dimensionality" v-model:feedback="feedback" @search="getSessions" @export="exportData" />
      <div class="container">
        <el-table :data="tableData" empty-text="-"  :row-style="{ height: '60px' }"  row-class-name="row">
          <el-table-column prop="id" label="Session ID" />
          <el-table-column prop="created_at" label="Start Query Time">
          </el-table-column>
          <el-table-column prop="title" label="Name">
            <template #default="scope">
              <el-tooltip
                :content="scope.row.title"
                placement="top"
                :show-after="200"
                :hide-after="0"
                popper-class="comments-tooltip"
              >
                <div class="comments-cell">
                  {{ scope.row.title }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>

          <!-- //如果是session label 叫Feedback score    其他叫Feedback --> 
          <el-table-column :label="dimensionality === 'session' ? 'Feedback score' : 'Feedback'">
            <template #default="scope">
              <div class="feedback-status">
                <template v-if="dimensionality === 'session'">
                  <!-- //如果是0 显示 -  -->
                  <span>{{scope.row.vote_status === '0'  ? '-' : scope.row.vote_status  }}</span>
                </template>
                <template v-else>
                  <div class="feedback-dot" :class="scope.row.vote_status === 'like' ? 'helpful' : scope.row.vote_status === 'dislike' ? 'not-helpful' : ''"></div>
                  <span>{{ scope.row.vote_status === 'like' ? 'Helpful' : scope.row.vote_status === 'dislike' ? 'Not helpful' : '-' }}</span>
                </template>
              </div>
            </template>
          </el-table-column>
          <!-- //加一个comments  字段也是comments     必须是session 才有 -->
          <el-table-column  v-if="dimensionality === 'session'" prop="comments" label="Comments">
            <template #default="scope">
              <el-tooltip
                :content="scope.row.comments"
                placement="top"
                :show-after="200"
                :hide-after="0"
                popper-class="comments-tooltip"
              >
                <div class="comments-cell">
                  {{ scope.row.comments }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Operation" width="100">
            <template #default="scope">
              <div class="operation" @click="handleShowHistory(scope.row)">
                <img src="@/assets/img/find-replace.svg" />
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="total" @size-change="getSessions" @current-change="getSessions"
                       v-model:page-size="pageSize" v-model:current-page="currentPage" layout="total,sizes, prev, pager, next"
                       :page-sizes="[10, 20, 30, 40, 50]"></el-pagination>
      </div>
      <HistoryDrawer v-model:visible="historyShow" :session="session2show"  :dimensionality="dimensionality" />
    </div>

  </div>
</template>
<script setup>
import SearchForm from "./SearchForm.vue";
import {onMounted, onUnmounted, ref,watch} from "vue";
import {getMessageDataToHistory, getSessionsByBotIdAndPageSize} from "@/api/api";
import HistoryDrawer from "./HistoryDrawer.vue";
import * as XLSX from 'xlsx'; // You may need to install this package: npm install xlsx

const tableData = ref([]);

const duration = ref([]);
const recommendation = ref("");
const state = ref("");
const total = ref(0);
const pageSize = ref(20);
const currentPage = ref(1);
const username = ref("")
const serialNum = ref("")
const dimensionality = ref("session")
const feedback = ref("")
const session2show = ref({});
const historyShow = ref(false);
const handleShowHistory =async  (session) => {
  session2show.value = session;
  historyShow.value = true;
};

// Exposed props
const props = defineProps({
  botId: {
    type: [Number, String],
    defaultValue: -1
  },
});

watch(dimensionality, () => {
  currentPage.value = 1;
  getSessions();
});

watch([username, serialNum, duration, feedback], () => {
  getSessions();
}, { deep: true });

const getSessions = () => {

  console.log("change")
  const payload = {
    bot_id:props.botId,
    page_size: pageSize.value,
    query_params: {},
  };

  //如果是session 使用page_num   不然使用page
  if (dimensionality.value === 'session') {
    payload.page_num = currentPage.value
  } else {
    payload.page = currentPage.value
  }


  if (recommendation.value) {
    payload.query_params.recommendation = {op: "eq", value: recommendation.value};
  }
  if (state.value) {
    payload.query_params.state = {op: "eq", value: state.value};
  }
  if (duration.value && duration.value.length === 2) {
    payload.query_params.created_at = {
      op: "between",
      value: [duration.value[0] + " 00:00:00", duration.value[1] + " 23:59:59"]
    }
  }

  if (username.value) {
    if (dimensionality.value === 'session') {
      payload.query_params.id = {op: "like", value: username.value}
    } else {
      payload.query_params.session_id = {op: "like", value: username.value}
    }
  }

  //如果是session 使用title 不然使用content
  if (serialNum.value) {
    if (dimensionality.value === 'session') {
      payload.query_params.title = {op: "like", value: serialNum.value}
    } else {
      payload.query_params.content = {op: "like", value: serialNum.value}
    }
  }



  // if (dimensionality.value) {
  //   payload.query_params.dimensionality = {op: "eq", value: dimensionality.value};
  // }
  if (feedback.value) {
    if (dimensionality.value === 'session') {
      if(feedback.value.length > 0 ){
        if(feedback.value.includes('all')){
          payload.query_params.vote_status = {op: "in", value: ['0', '1', '2', '3', '4', '5']};
        }else{
          payload.query_params.vote_status = {op: "in", value: feedback.value};
        }
      }

    } else {
      payload.query_params.action = {op: "eq", value: feedback.value};
    }
  }


  if(dimensionality.value === 'session'){
    getSessionsByBotIdAndPageSize(payload)
        .then((res) => {
          if (res) {
            res.list.forEach(element => {
              element['session_id'] = element.id
              element['duration'] = Math.ceil(element['duration'] / 60)
            });
            tableData.value = res.list;
            total.value = res.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }else{
    payload.query_params.content_type = 'question'
    payload.order=[{order:'created_at',sort:'desc'}]
    getMessageDataToHistory(payload).then((res)=>{
      if (res) {
            res.list.forEach(element => {
              element['session_id_temp'] = element.session_id
              element['id'] = element.session_id
              element['vote_status'] = element['action']
              element['title'] = element['content']
            });
            tableData.value = res.list;
            total.value = res.total;
          }


    })
  }


};

// Function to export data to Excel
const exportData = async () => {
  try {
    // Create a copy of the current payload but with a larger page size
    const exportPayload = {
      bot_id: props.botId,
      page_size: 1000, // Set page size to 1000 for export
      query_params: {},
    };

    // Set the correct page parameter based on dimensionality
    if (dimensionality.value === 'session') {
      exportPayload.page_num = 1;
    } else {
      exportPayload.page = 1;
    }

    // Apply all the same filters as the current view
    if (recommendation.value) {
      exportPayload.query_params.recommendation = {op: "eq", value: recommendation.value};
    }
    if (state.value) {
      exportPayload.query_params.state = {op: "eq", value: state.value};
    }
    if (duration.value && duration.value.length === 2) {
      exportPayload.query_params.created_at = {
        op: "between",
        value: [duration.value[0] + " 00:00:00", duration.value[1] + " 23:59:59"]
      }
    }

    //如果是session 使用id 不然使用session_id
    if (username.value) {
      if (dimensionality.value === 'session') {
        exportPayload.query_params.id = {op: "like", value: username.value}
      } else {
        exportPayload.query_params.session_id = {op: "like", value: username.value}
      }
    }
    if (serialNum.value) {
      if (dimensionality.value === 'session') {
        exportPayload.query_params.title = {op: "like", value: serialNum.value}
      } else {
        exportPayload.query_params.content = {op: "like", value: serialNum.value}
      }
    }
    if (feedback.value) {
      if (dimensionality.value === 'session') {
        //如果值有all 就穿 0 1 2 3 4 5 

        if(feedback.value.length > 0 ){
          if(feedback.value.includes('all')){
          exportPayload.query_params.vote_status = {op: "in", value: ['0', '1', '2', '3', '4', '5']};
        }else{
          exportPayload.query_params.vote_status = {op: "in", value: feedback.value};
        }
        }
      } else {
        exportPayload.query_params.action = {op: "eq", value: feedback.value};
      }
    }

    // Add content_type and order for message data if needed
    if (dimensionality.value !== 'session') {
      exportPayload.query_params.content_type = 'question';
      exportPayload.order = [{order: 'created_at', sort: 'desc'}];
    }

    // Fetch the data for export
    let exportData = [];
    if (dimensionality.value === 'session') {
      const res = await getSessionsByBotIdAndPageSize(exportPayload);
      if (res && res.list) {
        exportData = res.list.map(item => ({
          'Session ID': item.id,
          'Start Query Time': item.created_at,
          'Name': item.title,
          'Feedback Score': item.vote_status === '0' ? '-' : item.vote_status,
          'Comments': item.comments
        }));
      }
    } else {
      const res = await getMessageDataToHistory(exportPayload);
      if (res && res.list) {
        exportData = res.list.map(item => ({
          'Session ID': item.session_id,
          'Start Query Time': item.created_at,
          'Name': item.content,
          'Feedback': item.action === 'like' ? 'Helpful' : item.action === 'dislike' ? 'Not helpful' : '-'
        }));
      }
    }

    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    
    // Set column widths
    const columnWidths = [
      { wch: 40 },  // Session ID
      { wch: 25 },  // Start Query Time
      { wch: 60 },  // Name
      { wch: 15 },  // Feedback
      { wch: 60 }   // Comments 
    ];
    
    worksheet['!cols'] = columnWidths;
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Generate filename with current date
    const date = new Date();
    const dateStr = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    const filename = `history_export_${dateStr}.xlsx`;

    // Export the file
    XLSX.writeFile(workbook, filename);
  } catch (error) {
    console.error('Export failed:', error);
    // You might want to add an error notification here
  }
};

onMounted(() => {
});
onUnmounted(() => {
})
</script>
<style lang="less">
/* 移除 scoped，使样式全局生效 */


.comments-tooltip{
  max-width: 300px!important;
}

/* 其他样式保持 scoped */
.question_container{
  padding: 24px;
  height: calc(100vh - 170px);
  box-sizing: border-box;
  width: 100%;

  /deep/.el-table__header .el-table__cell{
    color: rgba(84, 94, 117, 0.80)!important;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 214.286% */
  }

  /deep/.el-form-item{
    margin-bottom: 18px!important;
  }


  /deep/ .el-table__row .cell{
    color: #033249!important;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
  }

  .question_content{
    height: calc(100vh - 170px);
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
  }
}

.title {
  color: #343c6a;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
}

.container {
  height: calc(100vh - 280px);
  display: block;
  background-color: #ffffff;
}

.el-table {
  display: block;
  color: #1d252d;
  font-size: 1rem;
  height: 95%;

  :deep(.el-table__header .el-table__cell) {
    color: #718ebf;
    font-size: 14px;
  }

  :deep(.el-table__row) {
    .cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 60px;
      padding: 5px 8px;
    }
  }
}

.state {
  display: flex;
  align-items: center;

  .dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .green {
    background-color: #16dbcc;
  }

  .grey {
    background-color: #cccfd6;
  }
}

.tag-container {
  display: flex;
  min-width: 300px;
  flex-wrap: wrap;
  width: 100%;
  flex-flow: wrap;
}

.label {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  margin-right: 12px;

  div {
    font-size: 0.9rem;
    padding: 5px 10px;
    margin: 4px 0;
    margin-right: 10px;
    border-radius: 4px;
    cursor: pointer;
  }

  .brand {
    background-color: #3ab8ff1f;
    color: #3ab8ff;
  }

  .budget {
    background-color: #f8cf911f;
    color: #fa9800;
  }

  .category {
    background-color: #c376ff1f;
    color: #c376ff;
  }

  .sku {
    background-color: #edf2f7;
    color: #99a0ad;
  }

  .other {
    background-color: #fff3f3;
    color: #ff6868;
  }
}

.plus-notify {
  position: absolute;
  display: inline-flex;
  background: #4bc6d6;
  height: 20px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fdfdfd;
  top: 0px;
  box-shadow: 1px 1px 6px 1px #ccc;
  bottom: -4px;
  border-radius: 50%;
  padding: 0 3px;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  text-align: center;
}

.el-pagination {
  width: 100%;
  padding: 1rem 0;
}

.operation {
  cursor: pointer;
  img{
    width: 20px;
    height: 20px;
  }
}


.username {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feedback-status {
  display: flex;
  align-items: center;
  gap: 8px;

  .feedback-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    
    &.helpful {
      background-color: #45C65A;
    }
    
    &.not-helpful {
      background-color: #F55;
    }
  }

  span {
    font-size: 14px;
    color: #033249;
  }
}

.comments-cell {
  cursor: pointer;
  width: 200px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}

:deep(.comments-tooltip) {
  max-width: 300px !important;
  word-break: break-word;
}
</style>