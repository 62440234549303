import { createApp } from 'vue'
import App from './App.vue'
import i18n from '@/locales/index.js'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import 'element-plus/theme-chalk/dark/css-vars.css'
import router from "./router"
import moment from 'moment';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Api from '@/api/index';
import "bootstrap-icons/font/bootstrap-icons.css"
import { createManager } from '@vue-youtube/core';
import "@/styles/common.less"
import "@/styles/index.less"
const app = createApp(App);
app.use(createManager())
const pinia = createPinia()
pinia.use(createPersistedState())
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(pinia)
app.use(i18n);
app.config.globalProperties.$t = i18n.global.t;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$i18n = i18n.global;
app.config.globalProperties.$api = Api;

app.use(ElementPlus, { size: 'small', zIndex: 3000, fontFamily: 'helvetica neue, helvetica, arial, sans-serif'})
app.use(router).mount('#app')

// Element plus select lazy load
app.directive('el-select-lazy',(el, binding) =>{
    const trigger = el.querySelector('.el-tooltip__trigger')
    const id = trigger.getAttribute('aria-describedby')
    if (id) {
        const popper = document.getElementById(id)
        const selectWrap = popper.querySelector('.el-scrollbar .el-select-dropdown__wrap')
        selectWrap.addEventListener('scroll', () => {
            const { scrollHeight, scrollTop, clientHeight } = selectWrap
            const condition = scrollHeight - scrollTop - 34 < clientHeight
            if (condition) binding.value()
        })
    }
})
