import { defineStore } from 'pinia'
import { taskStatus, getSoueceConfig } from "@/api/api";
import _ from "loadsh"

const sourceType = {
    RAG: "RAG",
    SOURCE_POOL: "SOURCE_POOL",
    AD: "AD"
}

export const useSourcesStore = defineStore('sources', {
    state: () => ({ soueces: {}, soueceConfigs: {}, sourcecallbacks: {} }),
    getters: {
    },
    actions: {
        async getSouces(id) {
            if (!id) {
                return {}
            }
            if (this.soueces[id]) {
                return this.soueces[id]
            }
            try {
                const souece = await taskStatus(id)
                this.soueces[id] = souece
                return souece

            } catch (e) {
                console.log(e)
                return {}
            }
        },

        async reOrderSource(bot_id, rag, source_pool = []) {
            return new Promise((resolve, reject) => {
                let config = this.soueceConfigs[bot_id]
                let callbacks = this.sourcecallbacks[bot_id]
                if (!callbacks) {
                    callbacks = []
                    this.sourcecallbacks[bot_id] = callbacks
                }
                const callback = (config) => {
                    if (!config) {
                        reject(new Error("empty"))
                        return
                    }
                    this.soueceConfigs[bot_id] = config
                    if (config.length == 0) {
                        resolve([...rag, ...source_pool])
                        return
                    }
                    let addRag = false
                    let addSourcePool = false
                    let res = []
                    for (const item of config) {
                        if (item.config_type == sourceType.AD) {
                            const sourceList = _.get(item.content, "list", [])
                            res = [...res, ...sourceList]
                        } else if (item.config_type == sourceType.RAG) {
                            addRag = true
                            res = [...res, ...rag]
                        } else if (item.config_type === sourceType.SOURCE_POOL) {
                            addSourcePool = true
                            res = [...res, source_pool]
                        }
                    }
                    if (!addRag) {
                        res = res.concat(rag)
                    }
                    if (!addSourcePool) {
                        res = res.concat(source_pool)
                    }
                    resolve(res)
                }
                if (!config) {
                    if (callbacks.length == 0) {
                        getSoueceConfig(bot_id).then((res) => {
                            this.sourcecallbacks[bot_id].forEach(i => {
                                i(res)
                            })
                            this.sourcecallbacks[bot_id] = []
                        }).catch((err) => {
                            console.log(err)
                            this.sourcecallbacks[bot_id].forEach(i => {
                                i()
                            })
                            this.sourcecallbacks[bot_id] = []
                        })

                    }
                    callbacks.push(callback)


                } else {
                    callback(config)
                }


            })

        }

    }
})