<template>
  <div class="sentosa-assistant-container">
    <div class="header">
      <img src="@/assets/img/sentosa/sentosa_logo.svg" style="margin-left: 50px;" class="assistant-logo"/>

      <div class="new_chat_view" v-if="['chatView'].includes(activeComponent) && isShowNewChat" @click="clickRestart">
        <el-tooltip class="box-item" effect="dark" content="Start a new chat" placement="top-start">
          <div class="new_chat_item_body">
            <img src="@/assets/img/sentosa/newChat.svg" class="new_chat_logo" />
            <div class="title">New topic?
              Start fresh here</div>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div :class="['wrapper',activeComponent ==='chatView' ? 'isChatView' :'' ]">
      <div class="sidebar">
        <ul class="nav">

          <li :class="{ active:['chatView'].includes(activeComponent)  }" @click="clickMenu('chatView')">
            <img src="@/assets/img/sentosa/new_chat_left_active_icon.svg" class="new_chat_left_icon" v-if="['chatView'].includes(activeComponent)"/>
            <img src="@/assets/img/sentosa/new_chat_left_icon.svg" class="new_chat_left_icon" v-else/>
            New Chat
          </li>

          <li :class="{ active:['SourceBaseList','AddSourceBase'].includes(activeComponent)  }" @click="clickMenu('SourceBaseList')">
            <img src="@/assets/img/sentosa/source_base_left_active_icon.svg" class="new_chat_left_icon" v-if="['SourceBaseList','AddSourceBase'].includes(activeComponent)"/>
            <img src="@/assets/img/sentosa/source_base_left_icon.svg" class="new_chat_left_icon" v-else/>
            Source Base
          </li>
          <li :class="{ active: activeComponent === 'HistoryComponent' }" @click="activeComponent = 'HistoryComponent'">
            <img src="@/assets/img/sentosa/history_left_active_icon.svg" class="new_chat_left_icon" v-if="activeComponent === 'HistoryComponent'"/>
            <img src="@/assets/img/sentosa/history_left_icon.svg" class="new_chat_left_icon" v-else/>
            History
          </li>
        </ul>
      </div>


        <SubChatView ref="subChatViewRef" v-if="activeComponent ==='chatView' && showContent"   @triggerShow="toggleShowContent"   :isAdmin="true"  :moresProblem="moresProblem" @update:isShowConversation="(val) => isShowNewChat = !val"/>
        <HistoryComponent v-if="activeComponent ==='HistoryComponent'" :bot-id="botId" />
        <SourceBaseList v-if="activeComponent ==='SourceBaseList'" :bot-id="botId"  @showAddSourceBase="showAddSourceBase" />
        <AddSourceBase v-if="activeComponent ==='AddSourceBase'" :bot-id="botId" @setActiveComponent="setActiveComponent"/>

         </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import HistoryComponent from './components/HistoryComponent.vue';
import AddSourceBase from './components/AddSourceBase.vue';
import SubChatView from './components/SubChatView.vue';
import SourceBaseList from './components/SourceBaseList.vue';
import {getChatBot, getChatBotId} from "@/api/api";
const activeComponent = ref('chatView');
const moresProblem = ref([]);
const botId = ref('')
const showContent = ref(true);
const subChatViewRef = ref(null)
const isShowNewChat = ref(false)



const clickRestart = () => {
  isShowNewChat.value = false;
  subChatViewRef.value.restart()
}


// 切换显示状态的函数
const toggleShowContent = () => {
  showContent.value = false;
  setTimeout(() => {
    showContent.value = true;
  }, 20); // 确保有时间间隔来触发生命周期
};


onMounted(async () => {


  // localStorage.setItem('hasChat', 'true')
  // if(localStorage.getItem('hasChat')){
  //   console.log('进入到这边吧')
  //   localStorage.clear()
  //   sessionStorage.clear()
  // }
  // 获取机器人常见问题

  const botIdTemp = await getChatBotId();
  localStorage.setItem('botId', botIdTemp)
  botId.value = botIdTemp
  const botInfo = await getChatBot(botIdTemp);
  moresProblem.value = botInfo.common_problem.map(item => item.value);
})

const setActiveComponent = (name)=>{
  activeComponent.value = name
}

//显示添加文件
const showAddSourceBase = () => {
  activeComponent.value = 'AddSourceBase'
}

//点击菜单
const clickMenu = (data)=>{
  if(activeComponent.value === data){
    return
  } 
  isShowNewChat.value = false;
  activeComponent.value = data

}

</script>

<style lang="scss">
.sentosa-assistant-container {
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  height: 80px;
  border-bottom: 1px solid #F4F4F5;
  font-family: Montserrat;
  justify-content: space-between;
  align-items: center;

  .new_chat_view {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 4px;
    margin-right: 64px;
    cursor: pointer;
    z-index: 2;

    .new_chat_item_body {
      display: flex;
      align-items: center;
      padding: 10px;

      .new_chat_logo {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        cursor: pointer;
      }

      .title {
        color: #033249;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        /* 116.667% */
        width: 95px;
      }

      &:hover {
        background-color: #F3F5F7;
      }
    }


  }



}


.isChatView{
  background: white!important;
}
.wrapper {
  display: flex;
  background-color: #F5F7F9;
  flex: 1;
}

.sidebar {
  width: 200px;
  background-color: #fff;
  padding-top: 24px;

}

.nav {
  margin: 0;
  width: 200px;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  li {
    list-style: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    backdrop-filter: blur(25px);
    margin-bottom: 2px;
    width: 100%;
    color: #5B6176;
    font-size: 14px;

    .new_chat_left_icon{
      width: 16px;
      height: 16px;
      margin-right: 12px;
      margin-left: 20px;
    }
  }
}

.nav li:hover{
  background: rgba(245, 146, 0, 0.08);
}
.nav li.active {
  color: #F59200;
  font-weight: 600;
}
</style>