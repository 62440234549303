<template>
  <div class="history_drawer_container">
    <el-drawer v-model="show" direction="rtl" size="58%">
      <template #header>
        <div class="history_drawer_header">
          <span class="header_title">{{ session.id }}</span>
        </div>
      </template>
      <template #default>
        <div class="messages-container" ref="historyContainerRef">
          <div
              v-for="(message, index) in history"
              :key="index"
              :class="['message', message.type]"
          >
            <div class="message-content">
              <img v-if="message.type === 'ai-message'" src="@/assets/img/sentosa/avatar.svg"
                   class="assistant-logo"/>
              <div class="body-view">

                <div class="content" v-if="message.type === 'ai-message'" v-html="message.content"></div>

                <div class="content" v-else>{{ message.content }}</div>
              </div>
            </div>

            <!-- 新增点赞视图 -->
            <div class="vote-actions" v-if="message.type === 'ai-message' && message.action">
              <div class="vote-button">
                <img
                  :src="require(`@/assets/img/sentosa/${message.action === 'like' ? 'helpful_active' : 'helpful'}.svg`)"
                  class="vote-icon"
                />
              </div>
              <div class="vote-button">
                <img
                  :src="require(`@/assets/img/sentosa/${message.action === 'dislike' ? 'no_help_active' : 'no_help'}.svg`)"
                  class="vote-icon"
                />
              </div>
            </div>

            <div class="based_source-view"
                 v-if=" message.reference_sources && message.reference_sources.length > 0 && message.type === 'ai-message'">
              <div v-for="basedSourceItem in message.reference_sources" :key="basedSourceItem.id">
                <div class="based_source">
                  {{ transformSourceName(basedSourceItem.source_name) }}
                  <img src="@/assets/img/sentosa/rightIcon.svg" class="rightIcon"/>
                </div>
              </div>
            </div>

          </div>


        </div>
      </template>
    </el-drawer>
  </div>
</template>
<script setup>
import {getSessionHistoryById} from "@/api/api";
import {onMounted, ref, watch, defineEmits} from "vue"

import _ from "lodash"
import hljs from "highlight.js";
import {chatResultFormater, encodeHtml, generateHTMLTable} from "@/utils/formater";

const props = defineProps({
  visible: {type: Boolean, default: false},
  session: {
    type: Object, default() {
      return {}
    }
  },
  dimensionality: {type: String, default: 'session'}
})
const emit = defineEmits(["update:visible"])
const show = ref(props.visible)
watch(() => props.visible, (val) => {
  show.value = val
  if (val) {
    history.value = []
    requestSessionHistory()
  }
})
watch(() => show.value, (val) => {
  emit("update:visible", val)
})
const history = ref([])
// watch(() => props.session, () => {
//   requestSessionHistory()
// })


const parseCodeText2Html = (input) => {
  const sections = extractCodeAndNonCodeSections(input)
  for (const section of sections) {
    let val = ""
    if (section.type === 'code') {
      if (section.language !== "plaintext") {
        try {
          val = hljs.highlight(section.text, {
            language: section.language == 'vue' ? 'html' : section.language,
            ignoreIllegals: true
          }).value
        } catch (e) {
          val = hljs.highlight(section.text, {language: "plaintext", ignoreIllegals: true}).value
        }
      } else {
        const {secondBest, value} = hljs.highlightAuto(section.text)
        if (secondBest) {
          const {value, language} = secondBest
          if (language) {
            section.language = language
          }
          val = value
        } else {
          val = value
        }

      }
      if (section.language) {
        section.class = `hljs language-${section.language}`
      }
    } else if (section.type == 'txt') {
      val = chatResultFormater(section.text)
    } else if (section.type == 'table') {
      val = generateHTMLTable(section.column, section.rows)
    }
    section.html = val
  }

  return sections
}


function extractCodeAndNonCodeSections(text) {
  const lines = text.split("\n")
  const languagePattern = /^```(\w*)$/
  const tableRowPattern = /^\|?(.+\|)+(.+)?$/
  const tableSpliterPattern = /(^\|?(\s*-+\s*\|)+(\s*-+)?$)|(^\|?((\s*-\s*)+\|)+(\s*-+)?$)/
  let section = []
  let language = ''
  let codeStart = false
  let column = []
  let tableHeader = ''
  let rows = []
  const sections = [];
  for (const line of lines) {
    const temp = line.trim()
    const match = languagePattern.exec(temp)
    const tableMatch = !codeStart && tableRowPattern.test(temp)
    if (match) {
      if (column.length) {
        sections.push({type: "table", language: language, column: [...column], rows: [...rows]})
        column = []
        rows = []
      }
      if (codeStart) {
        sections.push({type: "code", language: language, text: section.join("\n")})
        section = []
        codeStart = false
      } else {
        language = match[1]
        if (!language) {
          language = "plaintext"
        }
        if (section.length) {
          sections.push({type: "txt", language: null, text: section.join("\n")})
          section = []
        }
        codeStart = true
      }

    } else if (tableMatch) {
      if (column.length > 0) {
        const row = chatResultFormater(temp).split("|").map(i => i.trim())
        if (!row[0]) {
          row.shift()
        }
        if (!row[row.length - 1]) {
          row.pop()
        }
        while (row.length < column.length) {
          row.push('')
        }
        rows.push(row.slice(0, column.length))
      } else if (tableHeader) {
        if (tableSpliterPattern.test(temp)) {
          const row = temp.split("|").map(i => i.trim())
          if (!row[0]) {
            row.shift()
          }
          if (!row[row.length - 1]) {
            row.pop()
          }
          const header = chatResultFormater(tableHeader).split("|").map(i => i.trim())
          if (!header[0]) {
            header.shift()
          }
          if (!header[header.length - 1]) {
            header.pop()
          }
          if (row.length === header.length) {
            tableHeader = ''
            if (section.length) {
              if (codeStart) {
                sections.push({type: "code", language: language, text: section.join("\n")})
              } else {
                sections.push({type: "txt", language: null, text: section.join("\n")})
              }
              section = []
            }
            column = header
          } else {
            section.push(tableHeader)
            section.push(line)
          }
        } else {
          section.push(tableHeader)
          tableHeader = temp
        }

      } else {
        tableHeader = temp
      }
    } else {
      if (column.length) {
        sections.push({type: "table", language: language, column: [...column], rows: [...rows]})
        column = []
        rows = []
      }
      section.push(line)
    }
  }
  if (column.length) {
    sections.push({type: "table", language: language, column: [...column], rows: [...rows]})
    tableHeader = ''
  }
  if (tableHeader) {
    section.push(tableHeader)
  }
  if (section.length) {
    if (codeStart) {
      sections.push({type: "code", language: language, text: section.join("\n")})
    } else {
      sections.push({type: "txt", language: null, text: section.join("\n")})
    }
  }
  sections.forEach(e => {
    if (e.type === 'text') {
      e.text = encodeHtml(e.text)
    }
  })
  return sections
}

const transformSourceName = (sourceName) => {
  let result = sourceName
      .replace(/__COLON__/g, ':')
      .replace(/__SLASH__/g, '/')
      .replace(/__DOT__/g, '.');
  const txtRegex = /\.txt$/;
  if (txtRegex.test(result)) {
    result = result.replace(txtRegex, '');
  }
  return result;
};

const requestSessionHistory = () => {
  const sessionId = _.get(props.session, "session_id", "")
  if (sessionId === '') return
  const payload = {
    session_id: sessionId,
    page_size: 1000,
  }
//r如果不是session的话   需要添加query_params
  if (props.dimensionality !== 'session') {
    payload.query_params = {
      session_id: props.session.session_id_temp,
      conversation_turn: props.session.conversation_turn
    }
    payload.session_id = props.session.session_id_temp
  }


  getSessionHistoryById(payload).then((res) => {
    if (res && res.list) {

      res.list.forEach((item) => {
        if (item.content_type === 'question') {
          item.type = 'user-message'
        }

        if (item.content_type === 'answer') {
          item.type = 'ai-message'
          console.log('item====>', item)
          const contentText = parseCodeText2Html(item.content)


          if (item.reference_sources && item.reference_sources.length > 0) {
            // 先用 Set 去重
            let newCitsMap = new Set();
            item.citations.forEach((sentenItem) => {
              if (sentenItem.cites && sentenItem.cites.length > 0) {
                sentenItem.cites.forEach((citesItem) => {
                  newCitsMap.add(citesItem.order);
                });
              }
            });

            // 转回数组
            newCitsMap = Array.from(newCitsMap);

            // 过滤 sources
            item.reference_sources = item.reference_sources.filter((source) => {
              return newCitsMap.includes(source.order);
            });

            // 根据 citations 中 cites 的出现顺序重新排列 sources
            const citesOrder = [];
            item.citations.forEach((sentenItem) => {
              if (sentenItem.cites && sentenItem.cites.length > 0) {
                sentenItem.cites.forEach((citesItem) => {
                  // 只添加未重复的 id，保证按照首次出现顺序
                  if (!citesOrder.some((item) => item.id === citesItem.id)) {
                    citesOrder.push(citesItem);
                  }
                });
              }
            });

            // 重新排序 sources
            item.reference_sources = citesOrder.map((cite) =>
                item.reference_sources.find((source) => source.id === cite.id)
            ).filter(Boolean);
          }


          if (contentText && contentText.length > 0) {
            item.content = contentText[0].html
          }


        }
      })
      history.value = res.list
    }
  }).catch(err => {
    console.log(err)
    history.value = []
  })
}


// const fileId = ref("")
// // const page = ref(0)
// const fileName = ref('')
// const fileVisible = ref(false)
// const showLazadaReview = ref(false)
// const showItem = ref({})
// const showCite = (item) => {
//   if (item.source_type == 'lazada') {
//     showLazadaReview.value = true
//     showItem.value = item
//   } else {
//     if (checkIsLink(item.source_data)) {
//       const a = document.createElement('a');
//       a.href = item.source_data;
//       a.target = '_blank'
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//     } else {
//       fileVisible.value = true
//       page.value = item.page + 1 || 1
//       fileName.value = item.source_data
//       fileId.value = item.id
//     }
//   }
// }

onMounted(() => {
  // requestSessionHistory(props.session.session_id)
})
</script>
<style scoped lang="less">

.history_drawer_container {

  .header_title {
    color: #033249;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }

  /deep/ .el-drawer__header {
    padding: 15px 20px !important;
    margin-bottom: 0 !important;
    //border-bottom: 1px solid red!important;
    box-shadow: 0px -1px 0px 0px #DDE0E4 inset !important;
  }

  .history_drawer_header {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }

  .messages-container {
    overflow-y: auto; /* 启用滚动 */
    padding: 40px;
    max-height: calc(100vh - 53px); /* 留出空间给输入框和边距 */
  }

  .messages-container::-webkit-scrollbar {
    display: none;
  }

  .message {
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    margin-bottom: 24px;

    .message-content {
      display: flex;
      align-items: flex-start; /* 左对齐 */

      .assistant-logo {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        align-self: flex-start; /* 头像顶部对齐 */
      }

      .content {
        word-wrap: break-word;
        font-family: Montserrat;
        color: #033249;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px
      }


    }

    // 新增点赞样式
    .vote-actions {
      display: flex;
      gap: 8px;
      margin-left: 40px;
      margin-top: 8px;
      margin-bottom: 8px;

      .vote-button {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        .vote-icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .based_source-view {
      display: flex;
      flex-direction: column;

      .based_source {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        margin-left: 25px;
        border-radius: 6px;
        background: #F5F7F9;
        padding: 10px 12px;
        margin-top: 16px;
        color: #033249;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 114.286% */

        .rightIcon {
          width: 16px;
          height: 16px;
          margin-left: 8px;
        }
      }
    }


    &.user-message {
      .message-content {
        justify-content: flex-end; /* 用户消息右对齐 */

        .body-view {
          border-radius: 12px;
          background: #F3F5F7;

          .content {
            padding: 8px 16px;
          }
        }
      }
    }
  }
}


</style>