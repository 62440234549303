import { request } from '@/request/index.js'
import router from '@/router'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { ElMessage } from 'element-plus'
// eslint-disable-next-line no-unused-vars
class RetriableError extends Error { }

class FatalError extends Error { }
// const EventStreamContentType = 'text/event-stream'

const basePrefix = '/enterprise_search'


export const getVdbList = (params) => {
    const url = basePrefix + '/v1/chatbot_info'
    return request.$post(url, params);
}

export const createVdb = (params) => {
    const url = basePrefix + '/v1/chatbot'
    return request.$post(url, params)
}

export const updateChatBot = (botId, params) => {
    const url = basePrefix + '/v1/chatbot/' + botId
    return request.$put(url, params);
}


export const updateSessionFeedback = (sessionId, params) => {
    const url = basePrefix + '/v1/chatbot/session/' + sessionId
    return request.$put(url, params)
}


export const putSessionFeedback = (sessionId,params) => {
    const url = basePrefix + '/v1/chatbot/session/' + sessionId
    return request.$put(url, params)
}



export const getChatBot = (botId) => {
    const url = basePrefix + '/v1/chatbot/' + botId
    return request.$get(url);
}

export const getChatBotId = () => {
    const url = basePrefix + '/v1/sentosa/bot_id'
    return request.$get(url);
}



export const vdbSearch = (params = {}) => {
    const url = basePrefix + '/v1/chatbot'
    return request.$get(url, params)
}

export const chatStream = function (params, handle) {
    const url = basePrefix + '/v1/chatbot/streaming_chat'
    const MAX_RETRIES = 0;
    let promise = null
    let stopOutput = false
    // params["debug"] = true
    // params["bot_id"] = parseInt(params["bot_id"])
    const retry = (error, retryCount) => {
        if (retryCount > MAX_RETRIES || error.name === 'AbortError') {
            return false;
        }
        return true;
    };
    promise = new Promise((resolve, reject) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token')
        if (!token) {
            ElMessage.error('Need Login First!')
            router.push('/login')
            reject()
        }
        handle.stop = function () {
            stopOutput = true
            resolve({ data: "" })
        }
        fetchEventSource(url, {
            openWhenHidden: true,
            method: 'POST',
            timeout: 60 * 1000 * 2,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(params),
            params: params,
            async onopen(response) {
                if (response.ok) {
                    // everything's good
                }
                else {
                    throw new FatalError()
                }
                // } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                //     throw new FatalError()
                // } else {
                // }
            },
            onmessage(event) {
                if (event.data) {
                    try {
                        const data = JSON.parse(event.data)
                        if (data.debug) {
                            console.log(data.debug)
                        }
                        if (data.action === 'finished') {
                            resolve({ data })
                        } else {
                            if (!stopOutput) {
                                handle(data)
                            }
                        }
                    } catch (e) {
                        reject(e)
                        throw e
                    }
                }
            },
            onerror(error) {
                console.log(error)
                reject(error)
                throw error
            },
            retry
        })
    })
    return promise
}

export const enterpriseSearch = (params) => {
    const url = basePrefix + '/v1/chatbot/chat'
    return request.$post(url, params)
}

const sleep = (timeout) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(1)
        }, timeout);
    })
}
export const taskInfo = async (params) => {
    await sleep(300)
    const url = basePrefix + '/v1/chatbot/task_info'
    return await request.$post(url, params);
}

export const downloadVdb = (params) => {
    const url = basePrefix + '/v1/chatbot/download_qa'
    return request.$get(url, params)
}

export const deleteVdb = (params) => {
    const url = basePrefix + '/v1/chatbot'
    return request.$delete(url + `?bot_id=${encodeURIComponent(params.bot_id)}`)
}

export const requestClearVdb = params => {
    const url = basePrefix + "/v1/vectorstore/clean";
    return request.$delete(url + `?bot_id=${encodeURIComponent(params.bot_id)}`);
};

export const vdbCrawl = (params) => {
    const url = basePrefix + '/v1/chatbot/crawl'
    return request.$post(url, params)
}

export const exportQa = (params) => {
    const url = basePrefix + '/v1/vectorstore/export_qa'
    return request.$get(url, params)
}

export const exportQaDocument = (params) => {
    const url = basePrefix + '/v1/vectorstore/export_qa_json'
    return request.$get(url, params)
}
export const botOption = () => {
    const url = basePrefix + '/v1/chatbot/options'
    return request.$get(url)
}


export const queryQaList = (params) => {
    const url = basePrefix + '/v1/chatbot/qa/page'
    return request.$post(url, params)
}

export const updateQa = (params) => {
    const url = basePrefix + '/v1/chatbot/qa/update'
    return request.$post(url, params)
}

export const insertQa = (params) => {
    const url = basePrefix + '/v1/chatbot/qa/insert'
    return request.$post(url, params)
}

export const deleteDocument = (params) => {
    const url = basePrefix + '/v1/chatbot/document/delete'
    return request.$delete(url, params)
}

export const queryChunkList = (params) => {
    const url = basePrefix + '/v1/chatbot/chunk/page'
    return request.$post(url, params)
}

export const updateChunk = (params) => {
    const url = basePrefix + '/v1/chatbot/chunk/update'
    return request.$post(url, params)
}

export const insertChunk = (params) => {
    const url = basePrefix + '/v1/chatbot/chunk/insert'
    return request.$post(url, params)
}


export const taskStatus = (id) => {
    const url = basePrefix + '/v1/chatbot/task/' + id
    return request.$get(url)
}

export const taskPagination = (params) => {
    const url = basePrefix + '/v1/chatbot/task/page'
    return request.$post(url, params)
}

export const deleteTask = (id) => {
    const url = basePrefix + `/v1/chatbot/task/${id}`
    return request.$delete(url)
}


export const getTaskExtratData = (id) => {
    const url = basePrefix + `/v1/chatbot/task/${id}/extract_result`
    return request.$get(url)
}

export const generateQa = (params) => {
    const url = basePrefix + `/v1/vectorstore/qa/show`
    return request.$post(url, params)
}

export const queryAdvanceConfig = () => {
    const url = basePrefix + `/v1/chatbot/configs`
    return request.$get(url)
}

export const createChatShare = (params) => {
    const url = basePrefix + `/v1/chatbot/sharing_record`
    return request.$post(url, params)
}

export const getChatShare = (id) => {
    const url = basePrefix + `/v1/chatbot/sharing_record/${id}`
    return request.$get(url)
}

export const getSoueceConfig = (id) => {
    const url = basePrefix + `/v1/chatbot/source/config?bot_id=${id}`
    return request.$get(url)
}

export const getResourcePool = (data) => {
    const url = basePrefix + `/v1/resources_pool/page`
    return request.$post(url, data)
}

export const deleteResourcePool = (id) => {
    const url = basePrefix + `/v1/resources_pool/${id}`
    return request.$delete(url)
}

export const getResourcePoolById = (id) => {
    const url = basePrefix + `/v1/resources_pool/${id}`
    return request.$get(url)
}

export const searchResource = (params) => {
    const url = basePrefix + `/v1/chatbot/api_search`
    return request.$post(url, params)
}

export const getSearchResourceOptions = () => {
    const url = basePrefix + "/v1/chatbot/api_search"
    return request.$get(url)
}

export const saveResource = (params) => {
    const url = basePrefix + `/v1/resources_pool/upsert`
    return request.$post(url, params)
}

export const updateResource = (params) => {
    const url = basePrefix + `/v1/resources_pool/upsert`
    return request.$post(url, params)
}

export const speechToText = (params) => {
    const url = basePrefix + `/v1/stt`
    return request.$post(url, params)
}

export const getTranslateLanguage = () => {
    const url = basePrefix + "/v1/translate/support_language"
    return request.$get(url)
}

export const translate = (params) => {
    const url = basePrefix + "/v1/translate"
    return request.$post(url, params)
}

export const requestLogin = (params) => {
    const url = basePrefix + '/v1/account/login'
    return request.$post(url, params)
}

export const requestLoginEncryption = (params) => {
    const url = basePrefix + '/v1/account/encrypt'
    return request.$post(url, params)
}
export const requestMe = () => {
    const url = basePrefix + '/v1/account/me'
    return request.$get(url)
}
export const querySessionsState = (params) => {
    const url = basePrefix + '/v1/session_monitor/show'
    return request.$post(url, params)
}
export const getSessionsByBotIdAndPageSize = (params) => {
    const url = basePrefix + "/v1/chatbot/session"
    return request.$post(url, { ...params})
}


export const getMessageDataToHistory = (params) => {
    const url = basePrefix + "/v1/chatbot/history "
    return request.$post(url, { ...params})
}


export const getSessionsByBotId = (botId) => {
    const url = basePrefix + "/v1/chatbot/session"
    return request.$post(url, { bot_id: botId })
}

export const updateSessionName = (sessionId, params) => {
    const url = `${basePrefix}/v1/chatbot/session/${sessionId}`
    return request.$put(url, params)
}

export const getSessionData = (sessionId) => {
    const url = basePrefix + "/v1/chatbot/history "
    return request.$post(url, { session_id: sessionId, page_size:10000 })
}



export const deleteSession = (sessionId) => {
    const url = basePrefix + `/v1/chatbot/session?ids=${sessionId}`
    return request.$delete(url)
}
export const deletChatHistory = (historyId) => {
    const url = basePrefix + `/v1/chatbot/history?ids=${historyId.join(",")}`
    return request.$delete(url)
}

export const getSessionHistoryById = (params) => {
    const url = basePrefix + "/v1/chatbot/history"
    return request.$post(url, params)
}


export const likeMessage = (id, params) => {
    const url = basePrefix + `/v1/chatbot/history/${id}`
    return request.$put(url, params)
}


export const updateSessionUseTime = (params) => {
/*     const url = basePrefix + "/v1/session_monitor/change_state"
    return request.$post(url, params) */
    console.log(params);
    return new Promise((resolve) => {resolve("ok")});
}

export const copyShareSession = (id) => {
    const url = basePrefix + "/v1/chatbot/new_session"
    return request.$post(url, { sharing_id: id })
}

export const getTikTokEmbed = (turl) => {
    const url = `${basePrefix}/v1/tiktok_embed?url=${turl}`
    return request.$get(url)
}


export const pageProductReview = (params) => {
    const url = basePrefix + '/v1/source/review/list'
    return request.$post(url, params)
}

export const getAllProducts = (params) => {
    const url = basePrefix + '/v1/product/list'
    return request.$post(url, params)
}

export const getProductById = (params) => {
    const url = basePrefix + `/v1/product/${params}`
    return request.$get(url)
}

export const addCartProduct = (params) => {
    const url = basePrefix + '/v1/product_cart'
    return request.$post(url,params)
}

export const getCartProducts = (params) => {
    const url = basePrefix + '/v1/product_cart_list'
    return request.$post(url, params)
}

export const deleteCartProduct = (pid) => {
    const url = basePrefix + `/v1/product_cart/${pid}`
    return request.$delete(url)
}

export const createShoppingOrder = (params) => {
    const url = basePrefix + '/v1/shopping_order/create'
    return request.$post(url,params)
}

export const processShoppingOrder= (orderID) => {
    const url = basePrefix + `/v1/shopping_order/${orderID}/processing`
    return request.$get(url)
}

export const getShoppingOrders = (params) => {
    const url = basePrefix + '/v1/shopping_order_list'
    return request.$post(url,params)
}

export const completeShoppingOrder = (orderId) => {
    const url = basePrefix + `/v1/shopping_order/${orderId}/complete`
    return request.$get(url)
}

export const getOrderDetail = (orderId) => {
    const url = basePrefix + `/v1/shopping_order/${orderId}`
    return request.$get(url)
}

export const getNotifications = (params) => {
    const url = basePrefix + '/v1/notification_list'
    return request.$post(url,params)
}

export const clearAllNotifications = () => {
    const url = basePrefix + '/v1/notification'
    return request.$delete(url)
}

export const readNotifications = (params) => {
    const url = basePrefix + '/v1/notification/change_status'
    return request.$post(url, params)
}