import { useSourcesStore } from "@/store/sources";
import { chatStream } from "@/api/api";
import { useSessionStore } from "@/store";
import { nextTick, ref, computed } from "vue";
// import { getTopKImages } from "@/utils/util";
import _ from "loadsh"
import { v4 } from 'uuid';
import { ElMessage } from "element-plus";
import { useRoute } from "vue-router";


export const useChat = (botInfo, sessionId) => {
    const route = useRoute()
    const loadMessage = ref(false)
    const isDebug = ref(route.query.debug)
    const sessionStore = useSessionStore();
    const historyContainerRef = ref(null);
    const textarea = ref("");
    const soucesStore = useSourcesStore()
    const selectedSource = ref([]);
    const loading = computed({
        get: () => {
            return sessionStore.loading
        },
        set: (val) => {
            sessionStore.loading = val
        }
    });
    const textDisable = ref(false);
    const generating = ref(false)
    let streamHandle = null

    const chatData = ref([]);
    sessionStore.getSession(sessionId).then(res => {
        chatData.value = [...res]
        loadMessage.value = true
    })
    const handleEdit = async (item, newText) => {
        item = chatData.value.find(i => i.id == item.id)
        if (item.role == 'user') {
            const index = chatData.value.findIndex(msg => msg == item)
            if (index > -1 && chatData.value[index + 1]) {
                textarea.value = newText
                await sendStreamMessage()
            }
        } else {
            const targetItem = chatData.value.find(v => v.id == item.id)
            if (typeof (newText) === 'string') {
                targetItem.content = newText
                targetItem.sentences = []
            } else if (newText instanceof Object) {
                for (const key in newText) {
                    targetItem[key] = newText[key]
                }
            }
            chatData.value = [...chatData.value, item]
        }
        sessionStore.saveSession()
    }

    const sendRecommendQuestion = async (item) => {
        textarea.value = item.value
        await sendStreamMessage()
    }

    const handleDelete = (item) => {
        const aiMessageIndex = chatData.value.findIndex(i => i.id === item.id)
        if (aiMessageIndex > 0) {
            chatData.value.splice(aiMessageIndex - 1, 2)
            chatData.value = [...chatData.value]
            sessionStore.setChatHistory(sessionId, chatData.value)
        }
    }
    const scrollToBottom = _.throttle(() => {
            const dom =  historyContainerRef?.value?.$el || historyContainerRef?.value;
            if (dom) {
                if (dom.scrollHeight >= dom.offsetHeight) {
                    dom.scrollTop = dom.scrollHeight - dom.offsetHeight;
                }
            }
    }, 100);

    const sendStreamMessage = async (item, newMessage = false) => {
        if (loading.value) {
            return
        }
        let params = null
        const filter_params = {}
        if (selectedSource.value.length > 0) {
            filter_params.final_source = {
                op: "in",
                value: selectedSource.value.map(i => i.id)
            }
        }
        let aiMessage = {
            id: v4(),
            role: "assistant",
            loading: true,
            generating: false,
            status: "pending",
            content: "",
            sources: [],
            sentences: [],
            search_images: [],
            search_videos: [],

        }
        let userMessage = null
        if (item) {
            const aiMessageIndex = _.findIndex(chatData.value, i => i.id == item.id)
            if (aiMessageIndex < 0) {
                ElMessage.error('invalid message')
                return
            }
            if (!newMessage) {
                userMessage = chatData.value[aiMessageIndex - 1]
                aiMessage = chatData.value[aiMessageIndex]
                aiMessage = item
                aiMessage.loading = true
                aiMessage.status = 'pending'
                aiMessage.content = ''
                userMessage.status = "pending"
                aiMessage.type = ''
                aiMessage.sentences = []
                aiMessage.sources = []
                aiMessage.search_images = []
                aiMessage.search_videos = []
            } else {
                const tmp = chatData.value[aiMessageIndex - 1]
                userMessage = {
                    id: v4(),
                    role: "user",
                    status: "pending",
                    content: tmp.content
                }
                chatData.value.push(userMessage);
                chatData.value.push(aiMessage);

            }
        } else {
            if (!(textarea.value.trim())) {
                return
            }
            userMessage = {
                id: v4(),
                role: "user",
                status: "pending",
                content: textarea.value,
            }
            textarea.value = "";
            chatData.value.push(userMessage);
            chatData.value.push(aiMessage);
        }
        loading.value = true
        textDisable.value = true;
        const context = [
            ..._.map(chatData.value.filter((el) => el.type !== "error"), (i) => _.pick(i, ['content', 'role']))
        ]
        context.pop()
        params = {
            context,
            session_id: sessionId,
            filter_params,
            // bot_id: botInfo.value.id,
        };


        setTimeout(async () => {
            scrollToBottom();
        })

        const handle = function (data) {
            console.log('data', data)
            aiMessage.content += data.result
            if (data.citation && data.citation.length) {
                aiMessage.sentences = data.citation
            }
            if (data.sources && data.sources.length) {
                aiMessage.sources = data.sources
            }
            if (aiMessage.loading) {
                aiMessage.loading = false
                aiMessage.generating = true
                generating.value = true
            }
            if (!aiMessage.search_images || !aiMessage.search_images.length) {
                const filterImages = _.get(data, "search_images", []).slice(0, parseInt(_.get(botInfo.value, "feature_settings.search_images_num", 2)))
                aiMessage.search_images = filterImages
            }
            if (!aiMessage.search_videos || !aiMessage.search_videos.length) {
                const filterVideo = _.get(data, "search_videos", [])
                aiMessage.search_videos = filterVideo
            }
            if (_.isEmpty(aiMessage.products)) {
                const filterProduct = _.get(data,"products_info.list",[])
                aiMessage.products = filterProduct
            }

            if (data.code !== 200) {
                aiMessage.status = "error"
                userMessage.status = "error"
                loading.value = false
                textDisable.value = false;
            }
            chatData.value = [...chatData.value]
            scrollToBottom();
        }
        streamHandle = handle
        try {
            userMessage.status = 'pending'
            const data = await chatStream(params, handle)
            if (data.data.debug) {
                aiMessage.debug = data.data.debug
            }
            aiMessage.recommend = data.data.recommend
            chatData.value = [...chatData.value]
        } catch (e) {
            aiMessage.status = 'error'
            aiMessage.content = e.message
        }
        if (aiMessage) {
            aiMessage.loading = false
            aiMessage.generating = false
            aiMessage.status = 'success'
            userMessage.status = 'success'
        }
        generating.value = false
        // sessionStore.getBotSessions(botInfo.value.id)
        sessionStore.sessionHistory = [...chatData.value]
        // chatData.value = [...await sessionStore.getSession(sessionId)]

        await nextTick();
        /*        if (!item) {
                   scrollToBottom();
               } */
        loading.value = false
        textDisable.value = false;
    };
    const stopChat = () => {
        if (streamHandle) {
            streamHandle.stop()
        }
        streamHandle = null
        textDisable.value = false;
    };
    const messagePairs = computed(() => {
        const pairs = []
        let pair = null

        chatData.value.forEach(e => {

            if (pair == null && e.role == 'user') {
                pair = {}
                pair.user = {
                    ...e,
                    role: "user",
                }
            } else if (pair !== null && e.role == 'assistant') {
                pair.assistant = {
                    ...e,
                    role: "assistant",
                    sentences: e.sentences || e.citations,
                    sources: e.sources || e.reference_sources,
                    search_images: e.search_images || _.get(e, "search_data.search_images", []).slice(0, parseInt(_.get(botInfo.value, "feature_settings.search_images_num", 2))),
                    search_videos: e.search_videos || _.get(e, "search_data.search_videos", []),
                }

            }else if(e.role == 'assistant' && pair === null){
                //这一步用于处理很久不说话 自动发送一句话
                pair = {}
                pair.assistant = {
                    ...e,
                    role: "assistant",
                    sentences: e.sentences || e.citations,
                    sources: e.sources || e.reference_sources,
                    search_images: e.search_images || _.get(e, "search_data.search_images", []).slice(0, parseInt(_.get(botInfo.value, "feature_settings.search_images_num", 2))),
                    search_videos: e.search_videos || _.get(e, "search_data.search_videos", []),
                }
                pairs.push(pair)
                pair = null

            }
            if (pair && pair.user && pair.assistant) {
                pairs.push(pair)
                pair = null
            }
        })





        return pairs
    })
    return {
        soucesStore,
        sessionStore,
        streamHandle,
        selectedSource,
        textDisable,
        textarea,
        loading,
        generating,
        historyContainerRef,
        chatData,
        messagePairs,
        isDebug,
        loadMessage,
        sendRecommendQuestion,
        sendStreamMessage,
        handleDelete,
        handleEdit,
        // reorderSentences,
        stopChat
    }

}