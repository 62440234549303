<template>
    <div>
        <div v-html="textHtml" style="padding-left:16px" >
        </div>
    </div>
</template>
<script setup>
import { chatResultFormater } from "@/utils/formater"
import { computed } from "vue";
const props = defineProps({
    text: {
        type: String,
        default() {
            return ""
        }

    }
})

const textHtml = computed(() => {
    return chatResultFormater(props.text)
})
</script>