
export function isMobileDevice() {
  let isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobile/i.test(
    navigator.userAgent
  )
  let isPad = /iPad|Tablet/i.test(navigator.userAgent)
  return isMobile && !isPad
}

export function isIOSDevice() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

// export function isImplant() {
//   const queryParams = new URLSearchParams(window.location.search);
//   return queryParams.get("implant") === "true"
// }