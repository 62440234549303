
/**
 * api 路径
 */
const basePrefix = 'enterprise_search/'

const apiMap = {
  vdbUpload:basePrefix +'v1/chatbot/upload',//vdb上传接口
  vdbUploadQa:basePrefix +'v1/chatbot/upload_qa',//vdb上传qA
  vdbUploadQaJson:basePrefix +'v1/vectorstore/import_qa_json',//vdb上传qA


};
export default apiMap;
