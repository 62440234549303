<template>

  <div class="sourceBase_container">
    <div class="source_content">
      <div class="header">
        Add File
      </div>
      <div v-loading="taskStatus === 1" class="form_content">
        <div class="form_content_body">
          <el-form :model="fileUploadForm" ref="fileUploadFormRef" :rules="ruleUploadRule" label-position="top"
                   size="large">
            <el-form-item label="Task Status">
              <div class="taskStatus">
                <div class="round"></div>
                <div class="title">Not Start</div>
              </div>
            </el-form-item>
            <el-form-item label="ChunkSize" prop="chunkSize">
              <el-select placeholder="Select" v-model="fileUploadForm.chunkSize" style="width: 100%">
                <el-option v-for="item in chunkSizeOptions" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Separator" prop="separator">
              <el-select placeholder="Please select separator" multiple v-model="fileUploadForm.separator"
                         style="width: 100%">
                <el-option v-for="item in separatorOptions" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Concurrency Limit" prop="chunkSize">
              <el-input :min="1" type="number" v-model="fileUploadForm.concurrencyLimit" style="width: 100%">
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="generateQa">
              <el-checkbox v-model="fileUploadForm.generateQa" label="Generate FAQ" size="large"/>
            </el-form-item>
            <QaPrompt ref="qaPromptRef" :row="10" :botId="botId" :disabled="!fileUploadForm.generateQa" noBtn/>
          </el-form>
          <el-upload class="upload-demo" drag :multiple="false" :file-list="fileList" :disabled="disabledFileUpload"
                     ref="fileUploadRef" :data="{
        bot_id: botId,
        name: botInfo.name,
        concurrent_limit: fileUploadForm.concurrencyLimit,
        generate_qa: fileUploadForm.generateQa,
        ignore_same_file: fileUploadForm.ignoreSameFile,
        chunk_size: fileUploadForm.chunkSize,
        separator: JSON.stringify(fileUploadForm.separator),
      }" :action="actionUploadUrl" :on-success="onSuccess" :on-error="handleError" :on-change="fileChange"
                     :auto-upload="false" accept=".pdf,.md,.zip,.txt,.docx,.xlsx" :headers="headers">
            <el-icon class="el-icon--upload">
              <upload-filled/>
            </el-icon>
            <div class="el-upload__text"> Drop file here or <em>click to upload</em>
            </div>
            <template #tip>
              <div class="el-upload__tip" style=""> Supports PDF, Markdown,docx,xlsx zip, and text files，The support max
                file size is 50M
              </div>
            </template>
          </el-upload>
        </div>


      </div>
      <div class="sourceBase_container_footer">
        <el-button type="primary" size="large" style="background-color: #F59200; border-color: #F59200;"
                   @click="clickFileUploadTaskBtn" :disabled="isBtnDisabled">Execute
        </el-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref, reactive, onMounted} from "vue";
import {ElMessage, ElMessageBox} from "element-plus";
import Api from "@/api";
import QaPrompt from "@/components/qaPrompt.vue";
import {getChatBot} from "@/api/api";
import {useTokenStore} from "@/store/token";

const qaPromptRef = ref()
const taskStatus = ref(0);
const actionUploadUrl = "/" + Api.vdbUpload;
const fileUploadRef = ref();
const fileUploadFormRef = ref(null);
const disabledFileUpload = ref(false);
const fileList = ref([]);
const isBtnDisabled = ref(false);
const tokenStore = useTokenStore()
const headers = ref({Authorization: 'Bearer ' + tokenStore.getToken})
const props = defineProps({
  botId: {
    type: Number,
    defaultValue: -1
  },

});

const emits = defineEmits(["setActiveComponent"])

const botInfo = ref({})
const chunkSizeOptions = [
  {
    value: "500",
    label: "500",
  },
  {
    value: "1000",
    label: "1000",
  },
  {
    value: "1500",
    label: "1500",
  },
  {
    value: "2000",
    label: "2000",
  },
];

const separatorOptions = [
  {
    value: "\n",
    label: "wrap",
  },
  {
    // eslint-disable-next-line no-useless-escape
    value: "(?<=\\. )",
    label: "(?<=\\. )",
  },
  {
    // eslint-disable-next-line no-useless-escape
    value: "(?<=\\。)",
    label: "(?<=\\。)",
  },
  {
    value: " ",
    label: "One space",
  },
  {
    value: "",
    label: "Empty String",
  },
];

const fileUploadForm = ref({
  chunkSize: "1000",
  generateQa: false,
  concurrencyLimit: 5,
  ignoreSameFile: "false",
  separator: ["(?<=\\。)", "(?<=\\. )", "\n"],
});

const ruleUploadRule = reactive({
  separator: [
    {
      required: true,
      message: "Please select separator",
      trigger: "change",
    },
  ],
});

const fileChange = (file) => {
  console.log("fileChange===》", file);
  if (file.size >= 50 * 1024 * 1024) {
    ElMessage.error("upload file size must be less than 50M ");
    fileList.value = [];
    return;
  }
  if (file.status === "ready") {
    fileList.value = [file];
    uploadFile = file
  }
};

let uploadFile = null
const onSuccess = (res) => {
  if (res.code === 200) {
    fileUploadRef.value.clearFiles();
    fileUploadForm.value.ignoreSameFile = 'false'
    setTimeout(() => {
      emits('setActiveComponent', 'SourceBaseList')
    }, 100);
  }
};


const handleError = (error) => {
  uploadFile.status = "ready"
  fileList.value = [uploadFile];
  try {
    const data = JSON.parse(error.message)
    const code = data.code
    if (code == 202) {
      ElMessageBox.confirm(
          "The target file already exists. Do you want to re-upload it?",
          "Warning",
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
      ).then(async () => {
        console.log('进入这边')
        fileUploadForm.value.ignoreSameFile = 'true'
        fileUploadRef.value.submit();
        setTimeout(() => {
          emits('setActiveComponent', 'SourceBaseList')
        }, 100);


      }).finally(() => {
        fileList.value = []
      });
      return
    }
    ElMessage.error("upload failed:" + data.error)
    return
  } catch (e) {
    console.log(e)
  }
  ElMessage.error("upload failed:" + error.message)

}

/**
 * 点击fileUpload任务
 */
const clickFileUploadTaskBtn = async () => {
  console.log("fileUploadForm====>", fileUploadForm.value);
  qaPromptRef.value.updatePrompt()
  if (fileList.value.length === 0) {
    ElMessage.error("Please upload file");
    return;
  }
  await fileUploadFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      try {
        fileUploadRef.value.submit();
      } catch (e) {
        console.log(e)
      }

    } else {
      console.log("error submit!", fields);
    }
  });
};

onMounted(() => {
  getChatBot(props.botId).then((res) => {
    if (res && res.id) {
      botInfo.value = res
    }
  })
})


</script>

<style scoped lang="less">
.sourceBase_container {
  padding: 24px;
  height: calc(100vh - 81px);
  box-sizing: border-box;
  width: 100%;

  /deep/ .el-upload-dragger .el-upload__text em {
    color: #F59200 !important;
  }


  /deep/ .el-checkbox__label {
    color: rgba(84, 94, 117, 0.80) !important;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 257.143% */
  }

  /deep/ .el-upload__tip {
    color: rgba(84, 94, 117, 0.50);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }

  /deep/ .el-form-item__label {
    color: rgba(84, 94, 117, 0.80);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 230.769% */
    margin-bottom: 0px !important;
  }

  .sourceBase_container_footer{
    height: 64px;
    display: flex;
    align-items: center;
    border-top: 1px solid #D8DEE4; ;


  }

}

.source_content {
  background-color: #fff;
  border-radius: 20px;
  padding: 24px 32px;
  height: calc(100vh - 170px);
  overflow: hidden;
}

.header {
  color: #033249;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding-bottom: 8px;
  border-bottom: 1px solid #D8DEE4;
}

.form_content {
  margin-top: 16px;
  height: calc(100vh - 275px);
  overflow-y: scroll;

  .form_content_body{
    width: 34vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.taskStatus {
  display: flex;
  align-items: center;
}

.round {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: #CCCFD6;
  margin-right: 6px;
}

.title {
  color: #24292F;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.el-form-item--large {
  margin-bottom: 12px !important;
}


</style>