import { createI18n } from 'vue-i18n'
import en from './en.js'
import zh from './zh.js'

const i18n = createI18n({
  locale: 'en', // 默认语言
  messages: {
    en: {
      ...en
    },
    zh: {
      ...zh
    }
  }
})

export default i18n