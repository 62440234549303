<template>
  <div  class="source_baser_list_container">
    <div class="source_baser_list_content">
      <div class="source_baser_header">
        <el-form :inline="true" :model="searchForm">
          <el-form-item  >
            <div class="search_title">Import Time</div>
            <el-date-picker v-model="searchForm.dateRange" style="width: 400px;"   type="daterange"   value-format="YYYY-MM-DD" range-separator="-"
                            start-placeholder="Start date" end-placeholder="End date" size="large"  />

          </el-form-item>
          <el-form-item  >
            <div class="search_title">Status</div>
            <el-select v-model="searchForm.status" placeholder="Status" size="large" style="width:180px">
            <el-option v-for="item in statusOptions" style="font-weight: 500" :key="item.value" :label="item.value || item.label"
                       :value="item.value" />
          </el-select>
          </el-form-item>
        </el-form>
        <div class="end">
          <el-button type="primary" size="large"  style="background-color: #F59200; border-color: #F59200;" @click="clickSourceBase">Add New File</el-button>
        </div>
      </div>

      <div class="table-container">
        <el-table :data="taskList" style="height: 100%"  size="default"
                  :row-style="{ height: '60px' }" v-loading="loading">
          <el-table-column prop="source_data" label="Source Name"  :show-overflow-tooltip="true">
            <template #default="{ row }">
              <div style="text-overflow: ellipsis;overflow: hidden;">
                <a v-if="row.task_type === 'CRAWLING'" target="_blank" :href="row.source_data">{{ transformSourceName(row.source_data) }}
                </a>
                <span v-else>{{ transformSourceName(row.source_data) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="task_status" label="Status" >
            <template #default="{ row }">
              {{ convertTaskStatus(row.task_status) }}
            </template>
          </el-table-column>
          <el-table-column prop="generate_qa" label="Generate QA"  >
            <template #default="{ row }">
              {{ getGenerateQa(row) }}
            </template>
          </el-table-column>
          <el-table-column prop="task_type" label="Type"  ></el-table-column>
          <el-table-column prop="created_at" label="Created Time"  >
          </el-table-column>
          <el-table-column prop="start_time" label="Start Time"  >
            <template #default="{ row }">
              {{ row.start_time || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="end_time" label="End Time"  >
            <template #default="{ row }">
              {{ row.end_time || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="Action" width="110px" fixed="right">
            <template #default="{ row }">
              <div class="source_baser_operation" >
              <el-button :disabled="viewBtnDisabled(row)" :icon="View" link size="default"
                         @click="handleShowFile(row)"></el-button>
              <el-button :disabled="deleteBtnDisabled(row)" :icon="Delete" link size="default"
                         @click="handleDelete(row)"></el-button>
              </div>

            </template>
          </el-table-column>
        </el-table>
        <el-pagination :current-page="currentPage" :total="total" :page-sizes="[10, 20, 30, 50, 100]"
                       :page-size="pageSize" layout="total,sizes, prev, pager, next" @size-change="handlePageSizeChang"
                       @current-change="handleCurrentPageChange">
        </el-pagination>
      </div>


    </div>

  </div>
  <router-view></router-view>
  <TaskFilePreview v-model="fileDrawVisible" :taskInfo="selectedTask"></TaskFilePreview>
</template>
<script setup>
import { taskPagination, deleteTask } from "@/api/api";
import { onMounted, ref, watch, reactive, onUnmounted } from "vue";
import _ from "loadsh";
import { Delete, View } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import TaskFilePreview from "./TaskFilePreview.vue";
import { onBeforeRouteUpdate } from 'vue-router'


const taskList = ref([]);
const selectedTask = ref({});
const fileDrawVisible = ref(false);
const loading = ref(false)
const handleShowFile = (row) => {
  selectedTask.value = row;
  fileDrawVisible.value = true;
};
const searchForm = reactive({
  dateRange: [],
  status: "",
});

// 将__COLON__、__SLASH__和__DOT__替换为对应符号
const transformSourceName = (sourceName) => {
  let result = sourceName
      .replace(/__COLON__/g, ':')
      .replace(/__SLASH__/g, '/')
      .replace(/__DOT__/g, '.');
  const txtRegex = /\.txt$/;
  if (txtRegex.test(result)) {
    result = result.replace(txtRegex, '');
  }
  return result;
};


const emits = defineEmits(["showAddSourceBase"]);

const clickSourceBase = ()=>{
  emits("showAddSourceBase")
}
const getGenerateQa = (row) => {
  let generateQa = false
  if (row.task_type === "UPLOAD") {
    generateQa = _.get(row, "extra_data.run_params.generate_qa")
  } else {
    generateQa = _.get(row, "extra_data.run_params.import_setting.generate_qa")
  }
  if (generateQa === true || generateQa === 'true') {
    return "YES"
  } else {
    return "NO"
  }
}
const statusOptions = ref([
  { label: "All", value: "" },
  { value: "PENDING" },
  //    { value: "PREPROCESSING" },
  //    { value: "PREPROCESS_FAILED" },
  //    { value: "PREPROCESSED" },
  { value: "PROCESSING" },
  { value: "SUCCESS" },
  { value: "FAILED" },
  { value: "TIMEOUT" },
]);

// Exposed props
const props = defineProps({
  botId: {
    type: [Number, String],
    defaultValue: -1
  },
});

watch(
    () => props.botId,
    () => {
      getTaskList();
    }
);

watch(searchForm, () => {
  getTaskList();
});
/* Pagination */
const currentPage = ref(1);
const total = ref(0);
const pageSize = ref(10);
const handlePageSizeChang = (newVal) => {
  pageSize.value = newVal;
  getTaskList();
};
const handleCurrentPageChange = (newVal) => {
  currentPage.value = newVal;
  getTaskList();
};
const deleteBtnDisabled = (row) => {
  return (
      row.task_status !== "SUCCESS" &&
      row.task_status !== "FAILED" &&
      row.task_status !== "PENDING" &&
      row.task_status !== "PREPROCESS_FAILED" &&
      row.task_status !== "TIMEOUT"
  );
};
const viewBtnDisabled = (row) => {
  return (
      row.task_status !== "SUCCESS"
  )
}
onUnmounted(() => {
  loop = false;
});

onBeforeRouteUpdate((to, from, next) => {
  loop = false
  if (to.name === 'Source Database') {
    loopFetch()
  }
  next()
})

const handleDelete = async (data) => {
  ElMessageBox.confirm(
      "Are you sure to delete this task?<br/> <strong style='color:var(--el-color-danger)'>This will also delete all related data in vector database!</strong>",
      "Warning",
      {
        dangerouslyUseHTMLString: true,
        distinguishCancelAndClose: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }
  ).then(async () => {
    loading.value = true
    await deleteTask(data.id)
    ElMessage.success("Delete Complete!")
    getTaskList()
  });
};
const getTaskList = async () => {
  loading.value = true

  const query_params = {};
  query_params.task_type = {
    op: "in",
    value: ["UPLOAD", "CRAWLING"]
  }
  if (searchForm.status !== "") {
    if (searchForm.status === "PROCESSING") {
      query_params.task_status = {
        op: "in",
        value: ["PREPROCESSING", "PREPROCESSED"]
      }
    } else if (searchForm.status === "FAILED") {
      query_params.task_status = {
        op: "in",
        value: ["FAILED", "PREPROCESS_FAILED"]
      }
    } else {
      query_params.task_status = searchForm.status;
    }
  }
  if (searchForm.dateRange && searchForm.dateRange.length === 2) {


    query_params.created_at = {
      op: "between",
      value: [searchForm.dateRange[0] + " 00:00:00", searchForm.dateRange[1] + " 23:59:59"],
    };
  }
  const data = {
    bot_id: props.botId,
    page_size: pageSize.value,
    page: currentPage.value,
    query_params,
  };
  taskPagination(data).then((res) => {
    if (res && res.list) {
      taskList.value = res.list;
      total.value = res.total || 0;
    }
    loading.value = false
  });
};
const sleep = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time * 1000);
  });
};
let loop = true;
const loopFetch = async () => {
  loop = true
  while (loop) {
    await getTaskList();
    await sleep(30);
  }
};
const convertTaskStatus = (status) => {
  if (status === "PREPROCESSING" || status === "PREPROCESSED") {
    return "PROCESSING"
  }
  if (status === "PREPROCESS_FAILED") {
    return "FAILED"
  }
  return status
}
onMounted(() => {
  loopFetch();
});

// eslint-disable-next-line no-undef
defineExpose({ gettaskList: getTaskList });
</script>


<style lang="less">
.el-message-box{
  padding: 24px!important;


  .el-button{
    //--el-button-hover-bg-color:rgba(245, 146, 0, 0.08)!important;
    --el-button-hover-border-color:none!important;
  }
}



.el-message-box__btns{
  padding-top: 24px!important;

  .el-button{
    padding: 16px!important;
  }


}

</style>
<style lang="less" scoped>




.source_baser_list_container {
  font-family: Montserrat;
  padding: 24px;
  height: calc(100vh - 170px);
  box-sizing: border-box;
  width: 100%;

  .el-pagination {
    width: 100%;
    padding: 1rem 0;
  }

  /deep/ .el-table__row .cell{
    color: #033249!important;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
  }



  /deep/.el-table__header .el-table__cell{
    color: rgba(84, 94, 117, 0.80)!important;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 214.286% */
  }

}

.source_baser_list_content{
  background-color: #fff;
  border-radius: 20px;
  height: calc(100vh - 170px);
  padding: 20px;

  .table-container{
    height: calc(100vh - 310px);
    display: block;
    background-color: #ffffff;
  }
}


.source_baser_operation {
  // 针对嵌套的 .el-icon 元素
  ::v-deep .el-icon {
    font-size: 16px !important;
  }

  // Hover 状态
  &:hover {
    color: #F59200;
  }

  // 点击（active）状态
  &:active {
    color: #F59200;
  }

  // 可选：如果只想让 .el-icon 在 hover 和点击时变色
  ::v-deep .el-icon {
    &:hover {
      color: #F59200;
    }
    &:active {
      color: #F59200;
    }
  }
}
.source_baser_header {
  display: flex;
  align-content: center;
  justify-content: space-between;

  .start .el-select {
    margin-inline-start: 10px;
  }
  /deep/.el-form-item__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  /deep/.el-select-dropdown__item{
    font-weight: 500!important;
  }

  /deep/ .el-range-input{
    font-weight: 500!important;
  }

  .el-form-item {
    margin-right:12px ;
  }



  .search_title {
    width: 100%;
    font-size: 14px;
    line-height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.footer {
  display: flex;
  margin: 1rem;
  justify-content: space-between;

  .el-button {
    margin-left: auto;
  }
}
</style>
<style>
.el-select__selected-item{
  font-weight: 500!important;
}
</style>
