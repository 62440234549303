<template>
  <div class="login" :class="{ 'mobile': isMobileDevice() }">
    <img v-if="!isMobileDevice()" class="bg-icon" src="@/assets/img/icon.svg" height="30px">
    <img class="dot-1" src="@/assets/img/grident-dot.svg">
    <img class="dot-2" src="@/assets/img/grident-dot-red.svg">
    <el-card class="login-card" :class="{ 'mobile': isMobileDevice() }" shadow="hover">
      <div class="title">Log in</div>
      <div class="signup"> Need a Wiz Shop account?<span>Create an account</span>
      </div>
      <el-form :model="loginForm" size="large" label-position="top" status-icon>
        <el-form-item label="Username" prop="username">
          <template #label>
            <div class="label">Username</div>
          </template>
          <el-input v-model="loginForm.username" placeholder="yourname@comapny.com"></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <template #label>
            <div class="label">Password</div>
          </template>
          <el-input type="password" show-password v-model="loginForm.password" @keydown.enter="handleLogin"
            placeholder="Enter your password"></el-input>
        </el-form-item>
        <!--         <el-checkbox v-model="keepLogin" label="Keep me logged in" size="large"></el-checkbox> -->
        <span class="terms">Our <RouterLink to="/terms">Wiz Terms</RouterLink> and <RouterLink to="/privacy">Wiz Privacy</RouterLink></span>
        <div style="margin-top: 32px;">
          <el-button type="primary" @click="handleLogin"
            style="float: inline-end; font-size: 16px; font-weight: 500; width: 100%;">Submit</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script setup>
import { requestLogin, requestMe } from '@/api/api';
import { useTokenStore } from '@/store/token';
import { ElMessage } from 'element-plus';
import { onMounted, reactive, ref } from 'vue';
import { RouterLink, useRoute, useRouter } from 'vue-router';
import _ from "loadsh"
import { isMobileDevice } from '@/utils';

const keepLogin = ref(true)
const tokenStore = useTokenStore()
const router = useRouter()
var redirectPath = '/'
const loginForm = reactive({
  username: '',
  password: '',
})
const queryUserInfo = () => {
  requestMe().then(res => {
    if (res && res.data) {
      tokenStore.setUser(res.data)
    }
  })
}
const handleLogin = () => {
  const payload = { ...loginForm }
  requestLogin(payload).then(res => {
    if (res && res.data) {
      const token = _.get(res, 'data.access_token', null)
      if (token) {
        tokenStore.setToken(token, keepLogin.value)
        ElMessage.success('Login success!')
        queryUserInfo()
        setTimeout(() => {

          console.log('redirectPath====>',redirectPath)
          if(redirectPath === '/'){
            router.push('/ChatHistory')
          }else{
            router.push(redirectPath)
          }


          console.log('push', redirectPath)
        }, 200);
      } else {
        ElMessage.warning(res.data)
      }
    }
  }).catch(err => {
    ElMessage.error(err.data)
    console.log(err)
  })
}
const route = useRoute()
onMounted(() => {
  const { to } = route.query
  if (to) {
    redirectPath = atob(to).replace('#','')
  }
})
</script>
<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;

  .bg-icon {
    position: absolute;
    left: 40px;
    top: 40px;
  }

  .dot-1 {
    position: absolute;
    left: 158px;
    top: 95px;
  }

  .dot-2 {
    position: absolute;
    top: 3px;
    left: 307px;
  }
}

.login.mobile {
  .dot-1 {
    top: 80px;
    left: -40px;
    width: 120px;
    height: 150px;
  }

  .dot-2 {
    top: 3px;
    left: 60px;
    height: 114px;
    width: 94px;
  }
}

.login-card.mobile {
  --el-card-padding: 30px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin-top: 70px;
}

.login-card {
  width: 510px;
  height: fit-content;
  display: flex;
  margin-top: 20vh;
  flex-direction: column;
  background-color: #fff;
  z-index: 1000;
  border-radius: 10px;

  .title {
    color: #181E25;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    /* 110% */
  }

  .signup {
    color: #788BA5;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    text-align: start;
    margin-top: 20px;
    margin-bottom: 40px;

    span {
      color: #289CFF;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }

  --el-card-padding: 60px;
}

.terms {
  color: #788BA5;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-align: start;
  margin-top: 20px;
  margin-bottom: 40px;
  a {
    color: #788BA5;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    text-align: start;
    margin-top: 20px;
    margin-bottom: 40px
  }
}

.label {
  color: #181E25;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 187.5% */
}
</style>