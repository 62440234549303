<template>
  <div id="qa-prompt">
    <el-form-item label="Q&A Prompt">
      <el-input
        type="textarea"
        v-model="prompt"
        :disabled="disabled"
        placeholder="Please input prompt for question and answer pair generator"
        :rows="row"></el-input>
    </el-form-item>
    <el-form-item v-if="!noBtn">
      <el-button type="primary" @click="updatePrompt">Update</el-button>
    </el-form-item>
  </div>
</template>
<script setup>
  import { getChatBot, updateChatBot } from "@/api/api";
  import { onMounted, ref } from "vue";
  import _ from "loadsh";

  const props = defineProps({
    row: { type: Number, defaultValue: 5 },
    disabled: { type: Boolean, defaultValue: false },
    botId: { type: Number, defaultValue: -1 },
    noBtn: { type: Boolean, defaultValue: false }
  });
  const prompt = ref("");
  var feature_settings = {}

  const getQaPrompt = () => {
    getChatBot(props.botId).then((res) => {
      if (res) {
        prompt.value = _.get(res,"feature_settings.qa_generator_prompt");
        feature_settings = _.get(res,"feature_settings")
      }
    });
  };

  const updatePrompt = () => {
    if (feature_settings.qa_generator_prompt === prompt.value) {
      return;
    }
    const payload = {
      feature_settings: {
        ...feature_settings,
        qa_generator_prompt: prompt.value
      },
    };
    updateChatBot(props.botId,payload).then(() => {
      getQaPrompt()
    });
  };
  onMounted(() => {
    getQaPrompt();
  });

  defineExpose({ updatePrompt })
</script>
