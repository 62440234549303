import axios from 'axios';
import QS from 'qs';
import _ from "loadsh"
import { ElMessage } from "element-plus";
import router from '@/router';

axios.defaults.timeout = 2 * 60 * 1000 //修改默认超时时间两分钟
axios.defaults.baseURL = '/'

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    if (token) {
        config.headers.Authorization = 'Bearer ' + token
    }
    return config
}, (error) => {
    return Promise.reject(error)
})
axios.interceptors.response.use((res) => {
    return res
}, (error) => {
    if (error.response && error.response.status === 401) {
        router.push('/login')
    }
    return Promise.reject(error)
})
const $get = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            ...params,
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                let { data, code } = err.response.data;
                if (data instanceof ArrayBuffer) {
                    data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data)))
                }
                if ( code !== 401)
                    ElMessage.error(data)
                reject(data)
            })
    });
}

const $post = (url, params, config) => {
    return new Promise((resolve, reject) => {
        QS.stringify(params)
        axios.post(url, params, config)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                const code = _.get(err,"response.data.code",0)
                if (url !== '/enterprise_search/v1/account/login' && code !== 401)
                    ElMessage.error(_.get(err, "response.data.error","error"))
                reject(_.get(err, 'response.data'))
            })
    })
}

const $put = (url, params, config) => {
    return new Promise((resolve, reject) => {
        axios.put(url, params, config)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                const code = _.get(err,"response.data.code",0)
                if (code !== 401)
                    ElMessage.error(_.get(err, "response.data.error"))
                reject(_.get(err, 'response.data'))
            })
    })
}

const $delete = async (url, params) => {
    try{
        const res = await axios.delete(url, { data: params })
        return res.data
    }catch(err){
        const code = _.get(err,"response.data.code",0)
        if (code !== 401)
            ElMessage.error(_.get(err, "response.data.error"))
        throw new Error(_.get(err, 'response.data'))
    }
}

export const request = {
    $get,
    $post,
    $delete,
    $put,
    $axios: axios
}
