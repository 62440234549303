import { defineStore } from "pinia";

export const useTokenStore = defineStore('auth',{
  state : () => ({isAuthenticated: false, token: null, user: {}, keepLogin: false }),
  getters: {
    getAuthenticated: (state) => state.isAuthenticated,
    getToken: (state) => state.token,
    getUser: (state) => state.user,
    getUserId: (state) => state.user.id,
    getKeepLogin: (state) => state.keepLogin
  },
  actions: {
    setToken(token,keep) {
      if (token) {
        this.isAuthenticated = true
        this.token = token
        if (keep) localStorage.setItem('token', token)
        else sessionStorage.setItem('token', token)
      } else {
        this.logout()
      }
    },
    setUser(user) {
      if (user) {
        this.user = user
      } else {
        this.user = {}
      }
    },
    logout() {
      localStorage.removeItem('token')
      sessionStorage.removeItem('token')
      this.isAuthenticated = false
      this.token = null
    }
  },
  persist: true
})