import { PdfViewer, constant } from 'pdf-viewer-overlay'

import { reactive, computed } from 'vue'

const { ScaleMode } = constant

export const usePdfViewer = (el) => {
    const pdfData = reactive({
        totalPage: 0,
        currentPage: 0,
        scaleMode: 'autoFit',
        scale: 100
    })

    const pdf = {
        instance: null,
        data: pdfData
    }

    const scaleModeList = [
        { title: '自适应', value: ScaleMode.AUTO_FIT },
        // { title: '占满宽度', value: ScaleMode.FULL_WIDTH },
        { title: '原始', value: 'scale:100' },
        { title: '100%', value: 'scale:100' },
        { title: '150%', value: 'scale:150' },
        { title: '200%', value: 'scale:200' },
        { title: '250%', value: 'scale:250' },
        { title: '300%', value: 'scale:300' },
        { title: '350%', value: 'scale:350' },
        { title: '400%', value: 'scale:400' }
    ]

    const handleSelectScale = ({ key }) => {
        pdfData.scaleMode = key
        if ([ScaleMode.AUTO_FIT, ScaleMode.FULL_WIDTH].includes(key)) {
            pdf.instance.scaleMode = key
        } else {
            pdf.instance.scaleMode = ScaleMode.CUSTOMER
            const scale = parseInt(key.split(':')[1])
            if (isNaN(scale)) {
                return
            }
            pdf.instance.scale = (scale / 100)
        }
    }

    const getScaleMode = (scaleModeValue) => {
        const index = scaleModeList.findIndex(i => i.value === scaleModeValue)
        if (index > -1) {
            return scaleModeList[index]
        }
        return null
    }

    const scaleDisplayText = computed(() => {
        const scaleMode = getScaleMode(pdfData.scaleMode)

        if (!scaleMode) {
            return parseInt(pdfData.scale * 100) + '%'
        } else {
            return scaleMode.title
        }
    })

    const onPaginationChange = (page) => {
        console.log(page, "onPaginationChange====>")
        pdf.instance.jumpToPage(parseInt(page))
        pdfData.currentPage = page
    }

    const diff = (array1, array2) => {
        const left = []
        const right = []
        const same = []
        const leftSame = {}
        const rightSame = {}
        for (const i in array1) {
            for (const j in array2) {
                if (array1[1] === array2[j]) {
                    leftSame[i] = true
                    rightSame[j] = true
                }
            }
        }
        for (const i in array1) {
            if (!leftSame[i]) {
                left.push(array1[i])
            } else {
                same.push(array1[i])
            }
        }
        for (const j in array2) {
            if (!rightSame[j]) {
                right.push(array2[j])
            }
        }
        return { left, right, same }
    }

    const zoom = (type) => {
        pdfData.scaleMode = ScaleMode.CUSTOMER
        if (type === 'plus') {
            pdf.instance.zoomIn()
        } else {
            pdf.instance.zoomOut()
        }
        pdfData.scale = pdf.instance.scale
        pdf.instance.ScaleMode = ScaleMode.CUSTOMER
    }
    const createPdfInstance = () => {
        pdf.instance = new PdfViewer({
            el,
            darkMode: false,
        })
    }

    const loadPdf = async (url) => {
        if (!pdf.instance) {
            createPdfInstance()
        }
        await pdf.instance.loadPdf(url)
        pdfData.totalPage = pdf.instance.totalPage
        pdfData.currentPage = 1
        pdf.instance.render()
    }

    return {
        pdf,
        scaleModeList,
        scaleDisplayText,
        loadPdf,
        diff,
        zoom,
        createPdfInstance,
        handleSelectScale,
        onPaginationChange
    }
}
