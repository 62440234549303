<template>
    <div class="pd-view-container">
        <div class="pdf-header">
            <div class="show-preview header-item"> {{ }}</div>
            <div class="zoom header-item">
                <el-icon @click="zoom('minus')" style="margin-top:-4px">
                    <Remove />
                </el-icon>
                <div>
                    <el-dropdown :trigger="'click'">
                        <span class="scale-text">
                            {{ scaleDisplayText }}
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="handleSelectScale({ key: mode.value })"
                                    v-for="mode in scaleModeList" :key="mode.value" :index="mode.value">{{ mode.title
                                    }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
                <el-icon @click="zoom('plus')" style="margin-top:-4px">
                    <CirclePlus />
                </el-icon>
            </div>
            <div class="pagination header-item">
                <el-pagination small :page-size="1" v-model:current-page="pdf.data.currentPage" hide-on-single-page
                    @current-change="onPaginationChange" :pager-count="5" layout="prev, pager, next, jumper,total"
                    :total="pdf.data.totalPage" />
            </div>
        </div>
        <div id="pdf-viewer-wrapper" style="background:transparent">
        </div>
    </div>
</template>
  
<script setup>
import { ref, watch, onMounted } from 'vue'
import { usePdfViewer } from "./hooks.js"
import { CirclePlus, Remove } from "@element-plus/icons-vue";
const props = defineProps({
    url: {
        type: String,
        default: ''
    },
    page: {
        type: Number,
        default: 0
    }
})
const {
    pdf,
    scaleModeList,
    scaleDisplayText,
    loadPdf,
    zoom,
    handleSelectScale,
    onPaginationChange
} = usePdfViewer('pdf-viewer-wrapper')

// pdf
const pageChange = (pageCount) => {
    pdf.data.currentPage = pageCount
}

const scaleChange = (scale) => {
    pdf.data.scale = scale
}
watch(() => props.page, () => {
    setTimeout(() => {
        console.log("props.page",props.page)
        if (pdf.instance) {
            pdf.instance.jumpToPage(props.page)
        }
    }, 300)
})
// event handle definition end
const registerEvent = () => {
    if (!pdf.instance) {
        return
    }
    pdf.instance.registerEvent('pageChange', pageChange)
    pdf.instance.registerEvent('scaleChange', scaleChange)
}


const isInited = ref(false)

// 注册事件
const registerPdfViewerEvent = () => {
    return new Promise((resolve) => {
        if (!isInited.value) {
            setTimeout(() => {
                pdf.instance.addMouseEvent()
                registerEvent()
                isInited.value = true
            })
        }
        resolve()
    })
}

onMounted(() => {
    load()
})
watch(() => props.url, () => {
    load()
})
const unload = () => {
    pdf.instance?.removePdf()
}

const load = async () => {
    console.log("load")
    unload()
    if (props.url) {
        await loadPdf(props.url)
        await registerPdfViewerEvent()
        if (props.page) {
            setTimeout(() => {
                if (pdf.instance) {
                    pdf.instance.jumpToPage(props.page)
                }
            }, 100)
        }
    }

}


defineExpose({ unload, load })
</script>
  
<style lang="less" scoped >
.pd-view-container {
    width: 100%;
    height: 100%;
}


.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.pdf-header {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    background: transparent;

    .header-item {
        display: flex;
        justify-content: center;
        align-items: center;
        scale: 0.8;
        white-space: nowrap;
    }

    .zoom {
        width: 120px;
        height: 100%;

        .scale-text {
            cursor: pointer;
            padding-left: 8px;
            padding-right: 8px;
            font-size: 14px;
        }
    }

    .pagination {
        height: 100%;
        min-width: 300px
    }

    .show-preview {
        width: 0px;
        height: 100%;
    }

    .search {
        width: 200px;
        height: 100%;
    }
}

#pdf-viewer-wrapper {
    width: 100%;
    height: calc(100% - 48px);
    overflow: auto;
    position: relative;
}

#pdf-viewer {
    font-size: 12px;
    color: #4e5969;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
}

.textLayer span::selection {
    color: transparent;
    color: initial;
}
</style>
  