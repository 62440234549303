<!--常规页面-->
<template>
  <router-view></router-view>
</template>

<script setup>
</script>


<style>
*{
  //font-family: "helvetica neue", Arial, sans-serif;
  font-family: Montserrat;
}
#app {
  //font-family: "helvetica neue", Arial, sans-serif;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
html {
  height: 100%;
}
input {
  -webkit-text-size-adjust: 100%;
}
</style>
