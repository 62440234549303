import { defineStore } from 'pinia'
import { v4 } from 'uuid';
import _ from "loadsh"
import { getSessionData, getSessionsByBotId, deleteSession, updateSessionUseTime, updateSessionName, copyShareSession } from "@/api/api"



export const useSessionStore = defineStore('session', {
  state: () => ({
    sessionData: [], sessionHistory: [], sessionId: v4(), loading: false,
    sessionTimers: {},
    sessionActivateTime: null,
    timer: null
  }),
  getters: {
  },
  actions: {
    clearSession() {

    },
    
    startTimer() {
      this.timer = setTimeout(async () => {
        const sessionData = this.sessionTimers[this.sessionId] || { time: 0, status: "online" }
        this.sessionTimers[this.sessionId] = sessionData
        sessionData.status = 'online'
        sessionData.time += (new Date().getTime() - this.sessionActivateTime) / 1000
        this.sessionActivateTime = new Date().getTime()
        const data = []
        for (const key in this.sessionTimers) {
          const item = this.sessionTimers[key]
          data.push({ "session_id": key, 'online_time': item.time, state: item.status })
        }
        if (data.length == 0) {
          return
        }
        try {
          await updateSessionUseTime({ list: data })
          this.sessionTimers = {}
        } catch (e) {
          console.log(e)
        }
        this.startTimer()
      }, 10000)
    },

    saveSession() {
    },

    async setShareSession(shareId) {
      const session = await copyShareSession(shareId)
      this.updateSessionId(session.data)
    },

    addSession(value) {
      this.sessionData.push(value)
    },
    async getSession(sessionId) {
      if (sessionId) {
        const data = await getSessionData(sessionId)
        data.list.forEach(item => {
          if (item.content_type == 'question') {
            item.role = 'user'
          } else {
            item.role = 'assistant'
          }
          item.products = _.get(item,'extra_data.products_info.list',[])
        })
        this.sessionHistory = data.list
        return data.list
      } else {
        return []
      }
    },

    async getBotSessions(botId) {
      if (botId) {
        try {
          const res = await getSessionsByBotId(botId)
          this.sessionData = res.list.map(item => ({ ...item, products: _.get(item,'extra_data.products_info.list',[])}))

        } catch (e) {
          console.log(e)
          this.sessionData = []
        }
      } else {
        this.sessionData = []
      }
    },

    setChatHistory(sessionId, history) {
      const session = this.sessionData.find(item => item.id == sessionId)
      if (session) {
        session.history = history
      }
    },
    clearChatHistory(sessionId) {
      let session = this.sessionData.find(i => i.id === sessionId)
      if (session) {
        session.history = []
      }
    },

    updateSessionId(sessionId) {
      if(!sessionId){
          sessionId = v4()
      }
      if (!this.timer) {
        this.startTimer()
      }
      if (!this.loading) {
        if (this.sessionId && this.sessionActivateTime) {
          const sessionData = this.sessionTimers[this.sessionId] || { time: 0, status: "offline" }
          this.sessionTimers[this.sessionId] = sessionData
          sessionData.status = 'offline'
          sessionData.time += (new Date().getTime() - this.sessionActivateTime) / 1000
        }

        this.sessionId = sessionId
        if (sessionId) {
          this.sessionActivateTime = new Date().getTime()
          const sessionData = this.sessionTimers[this.sessionId] || { time: 0, status: "online" }
          sessionData.status = 'online'
          this.sessionTimers[this.sessionId] = sessionData
        }

      }
    },

    async updateSessionName(id, name) {
      await updateSessionName(id, { title: name })
    },

    async deleteSession(id) {
      if (this.loading) {
        return
      }
      this.sessionData = this.sessionData.filter((el) => el.id !== id)
      if (id === this.sessionId) {
        this.sessionId = v4()
      }
      await deleteSession(id)

    }
  },
})